
import { partnerAuthConfig } from "@/config/auth-config";
import { paths } from "@/router/routes";
import { Vue, Component } from "vue-property-decorator";

interface Product {
  id: string;
  name: string;
  image: string;
  pricePerKWh: number;
  pricePerMonth: number;
  points: string[];
}

@Component
export default class InnovativePowerQuestionnaire extends Vue {
  step:
    | "customer-type"
    | "commercial-products"
    | "personal-information"
    | "payment-info"
    | "download-ppa"
    | "residential-contact-us" = "customer-type";

  customerType: "commercial" | "residential" = "commercial";

  commercialProducts: Product[] = [
    {
      id: "eco",
      name: "PV PRO eco",
      image: "https://cdn.zaehlerfreunde.com/misc/ip-product-eco.png",
      pricePerKWh: 18,
      pricePerMonth: 10,
      points: [
        "Full-service electricity supply incl. PV from your own roof depending on your demand",
        "Our professional and affordable eco tariff with residual electricity from a certified supplier",
        "Completion in just a few minutes without the hassle of paperwork!",
      ],
    },
    {
      id: "eco",
      name: "PV PRO green",
      image: "https://cdn.zaehlerfreunde.com/misc/ip-product-green.png",
      pricePerKWh: 20,
      pricePerMonth: 10,
      points: [
        "Full-service green electricity supply incl. PV from your own roof depending on your demand",
        "Our professional green tariff with green residual electricity from a certified supplier",
        "Completion in just a few minutes without the hassle of paperwork!",
      ],
    },
  ];

  selectProduct(product: string) {
    this.step = "personal-information";
  }

  mounted(): void {
    partnerAuthConfig.then((authConfig) => {
      if (authConfig.getPartnerId() !== "ip") {
        this.$router.replace(paths.home);
      }
    });
  }

  get backEnabled(): boolean {
    return this.step !== "customer-type";
  }

  get nextEnabled(): boolean {
    switch (this.step) {
      case "customer-type":
      case "personal-information":
      case "payment-info":
        return true;
      default:
        return false;
    }
  }

  back() {
    switch (this.step) {
      case "residential-contact-us":
        this.step = "customer-type";
        break;
      case "commercial-products":
        this.step = "customer-type";
        break;
      case "personal-information":
        this.step = "commercial-products";
        break;
      case "payment-info":
        this.step = "personal-information";
        break;
      case "download-ppa":
        this.step = "payment-info";
        break;
    }
  }

  next() {
    switch (this.step) {
      case "customer-type":
        if (this.customerType === "commercial") {
          this.step = "commercial-products";
        } else {
          this.step = "residential-contact-us";
        }
        break;
      case "personal-information":
        this.step = "payment-info";
        break;
      case "payment-info":
        this.step = "download-ppa";
        break;
    }
  }
}
