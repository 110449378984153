
import { pending, failure, success, initialized, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { ChargecloudRegistrationDetails, DeviceRegistration } from "zaehlerfreunde-proto-types/device_registration_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import {} from "zaehlerfreunde-central/device_service_pb";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import spaces from "@/store/modules/spaces";

@Component
export default class ChargecloudConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteCall<UserError> = initialized;

  evseId: string = "";

  back(): void {
    this.$router.go(-1);
  }

  async onRegisterClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice ?? "false");
    registration.setSpaceMapping(spaceMapping);

    const details = new ChargecloudRegistrationDetails();
    if (this.evseId) {
      details.setEvseId(this.evseId);
      registration.setChargecloud(details);

      const request = new RegisterDeviceRequest();
      request.setRegistration(registration);
      this.registerDeviceCall = pending;

      try {
        await deviceServiceClient.registerDevice(request, {});
        this.registerDeviceCall = success(void 0);
        this.$router.go(-1);
      } catch (error) {
        this.registerDeviceCall = failure(userErrorFrom(error));
      }
    }
  }
}
