
import { tariffServiceClient } from "@/config/service-clients";
import { partnerModule } from "@/store/modules/partner";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GridDataType, UploadGridDataRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class GridDataImport extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  @Prop() value: boolean;

  uploadGridDataCall: RemoteCall<UserError> = initialized;
  csvFile: File | null = null;
  progress: number = 0;

  availableFileTypes: { text: string; value: GridDataType }[] = [
    {
      text: "Netzentgelte",
      value: GridDataType.GRID_COST,
    },
    {
      text: "PLZ-Netz-Zuordnung",
      value: GridDataType.ZIP_CODE_GRID_MAPPING,
    },
    {
      text: "Straßen-Netz-Zuordnung",
      value: GridDataType.ADDRESS_GRID_MAPPING,
    },
    {
      text: "Konzessionsabgaben",
      value: GridDataType.CONCESSION_FEES,
    },
  ];

  selectedFileType: GridDataType | null = null;

  @Watch("value")
  onValueChanged(): void {
    this.$emit("input", this.value);
    if (this.progress >= 100) {
      this.progress = 0;
      this.csvFile = null;
      this.selectedFileType = null;
      this.uploadGridDataCall = initialized;
    }
  }

  get isDataComplete(): boolean {
    return this.selectedFileType != null && this.csvFile != null;
  }

  async startImport(): Promise<void> {
    const csvFile = this.csvFile;
    const selectedFileType = this.selectedFileType;

    if (selectedFileType == null || csvFile == null) {
      return;
    }

    const fileContent = await new Uint8Array(
      await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", (e) => resolve(e.target?.result as Iterable<number>));
        reader.addEventListener("error", reject);
        reader.readAsArrayBuffer(csvFile);
      })
    );

    let importId = "";

    for (let i = 0; i < fileContent.length; i += 1980000) {
      const start: number = i;
      const end: number = start + 2000000;
      var chunk = fileContent.subarray(start, end);

      this.progress = (end / fileContent.length) * 100;

      try {
        this.uploadGridDataCall = pending;
        const request = new UploadGridDataRequest();
        request.setFileContent(chunk);
        request.setType(selectedFileType);
        request.setImportId(importId);
        request.setIsLastChunk(end >= fileContent.length);

        const response = await tariffServiceClient.uploadGridData(request, {});
        importId = response.getImportId();
        this.uploadGridDataCall = success(void 0);
      } catch (error) {
        this.uploadGridDataCall = failure(userErrorFrom(error));
        break;
      }
    }
    if (this.uploadGridDataCall.error == null) {
      this.value = false;
    }
  }
}
