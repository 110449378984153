/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_advisory_service_pb from '../../services/central/advisory_service_pb';
import * as services_central_device_service_pb from '../../services/central/device_service_pb';


export class DeviceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetDevice = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetDevice',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetDeviceRequest,
    services_central_device_service_pb.GetDeviceResponse,
    (request: services_central_device_service_pb.GetDeviceRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetDeviceResponse.deserializeBinary
  );

  getDevice(
    request: services_central_device_service_pb.GetDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetDeviceResponse>;

  getDevice(
    request: services_central_device_service_pb.GetDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDeviceResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetDeviceResponse>;

  getDevice(
    request: services_central_device_service_pb.GetDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetDevice',
        request,
        metadata || {},
        this.methodInfoGetDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetDevice',
    request,
    metadata || {},
    this.methodInfoGetDevice);
  }

  methodInfoDeleteDevice = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/DeleteDevice',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.DeleteDeviceRequest,
    services_central_device_service_pb.DeleteDeviceResponse,
    (request: services_central_device_service_pb.DeleteDeviceRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.DeleteDeviceResponse.deserializeBinary
  );

  deleteDevice(
    request: services_central_device_service_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.DeleteDeviceResponse>;

  deleteDevice(
    request: services_central_device_service_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.DeleteDeviceResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.DeleteDeviceResponse>;

  deleteDevice(
    request: services_central_device_service_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.DeleteDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/DeleteDevice',
        request,
        metadata || {},
        this.methodInfoDeleteDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/DeleteDevice',
    request,
    metadata || {},
    this.methodInfoDeleteDevice);
  }

  methodInfoGetUserDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetUserDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetUserDevicesRequest,
    services_central_device_service_pb.GetUserDevicesResponse,
    (request: services_central_device_service_pb.GetUserDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetUserDevicesResponse.deserializeBinary
  );

  getUserDevices(
    request: services_central_device_service_pb.GetUserDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetUserDevicesResponse>;

  getUserDevices(
    request: services_central_device_service_pb.GetUserDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetUserDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetUserDevicesResponse>;

  getUserDevices(
    request: services_central_device_service_pb.GetUserDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetUserDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetUserDevices',
        request,
        metadata || {},
        this.methodInfoGetUserDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetUserDevices',
    request,
    metadata || {},
    this.methodInfoGetUserDevices);
  }

  methodInfoGetDevicesInSpace = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetDevicesInSpace',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetDevicesInSpaceRequest,
    services_central_device_service_pb.GetDevicesInSpaceResponse,
    (request: services_central_device_service_pb.GetDevicesInSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetDevicesInSpaceResponse.deserializeBinary
  );

  getDevicesInSpace(
    request: services_central_device_service_pb.GetDevicesInSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetDevicesInSpaceResponse>;

  getDevicesInSpace(
    request: services_central_device_service_pb.GetDevicesInSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDevicesInSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetDevicesInSpaceResponse>;

  getDevicesInSpace(
    request: services_central_device_service_pb.GetDevicesInSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDevicesInSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetDevicesInSpace',
        request,
        metadata || {},
        this.methodInfoGetDevicesInSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetDevicesInSpace',
    request,
    metadata || {},
    this.methodInfoGetDevicesInSpace);
  }

  methodInfoAddDeviceToSpace = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/AddDeviceToSpace',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.AddDeviceToSpaceRequest,
    services_central_device_service_pb.AddDeviceToSpaceResponse,
    (request: services_central_device_service_pb.AddDeviceToSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.AddDeviceToSpaceResponse.deserializeBinary
  );

  addDeviceToSpace(
    request: services_central_device_service_pb.AddDeviceToSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.AddDeviceToSpaceResponse>;

  addDeviceToSpace(
    request: services_central_device_service_pb.AddDeviceToSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.AddDeviceToSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.AddDeviceToSpaceResponse>;

  addDeviceToSpace(
    request: services_central_device_service_pb.AddDeviceToSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.AddDeviceToSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/AddDeviceToSpace',
        request,
        metadata || {},
        this.methodInfoAddDeviceToSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/AddDeviceToSpace',
    request,
    metadata || {},
    this.methodInfoAddDeviceToSpace);
  }

  methodInfoUpdateDeviceDetails = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/UpdateDeviceDetails',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.UpdateDeviceDetailsRequest,
    services_central_device_service_pb.UpdateDeviceDetailsResponse,
    (request: services_central_device_service_pb.UpdateDeviceDetailsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.UpdateDeviceDetailsResponse.deserializeBinary
  );

  updateDeviceDetails(
    request: services_central_device_service_pb.UpdateDeviceDetailsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.UpdateDeviceDetailsResponse>;

  updateDeviceDetails(
    request: services_central_device_service_pb.UpdateDeviceDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.UpdateDeviceDetailsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.UpdateDeviceDetailsResponse>;

  updateDeviceDetails(
    request: services_central_device_service_pb.UpdateDeviceDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.UpdateDeviceDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/UpdateDeviceDetails',
        request,
        metadata || {},
        this.methodInfoUpdateDeviceDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/UpdateDeviceDetails',
    request,
    metadata || {},
    this.methodInfoUpdateDeviceDetails);
  }

  methodInfoRegisterDevice = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/RegisterDevice',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.RegisterDeviceRequest,
    services_central_device_service_pb.RegisterDeviceResponse,
    (request: services_central_device_service_pb.RegisterDeviceRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.RegisterDeviceResponse.deserializeBinary
  );

  registerDevice(
    request: services_central_device_service_pb.RegisterDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.RegisterDeviceResponse>;

  registerDevice(
    request: services_central_device_service_pb.RegisterDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.RegisterDeviceResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.RegisterDeviceResponse>;

  registerDevice(
    request: services_central_device_service_pb.RegisterDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.RegisterDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/RegisterDevice',
        request,
        metadata || {},
        this.methodInfoRegisterDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/RegisterDevice',
    request,
    metadata || {},
    this.methodInfoRegisterDevice);
  }

  methodInfoGetDeviceTypes = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetDeviceTypes',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetDeviceTypesRequest,
    services_central_device_service_pb.GetDeviceTypesResponse,
    (request: services_central_device_service_pb.GetDeviceTypesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetDeviceTypesResponse.deserializeBinary
  );

  getDeviceTypes(
    request: services_central_device_service_pb.GetDeviceTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetDeviceTypesResponse>;

  getDeviceTypes(
    request: services_central_device_service_pb.GetDeviceTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDeviceTypesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetDeviceTypesResponse>;

  getDeviceTypes(
    request: services_central_device_service_pb.GetDeviceTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDeviceTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetDeviceTypes',
        request,
        metadata || {},
        this.methodInfoGetDeviceTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetDeviceTypes',
    request,
    metadata || {},
    this.methodInfoGetDeviceTypes);
  }

  methodInfoGetDeviceProviders = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetDeviceProviders',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetDeviceProvidersRequest,
    services_central_device_service_pb.GetDeviceProvidersResponse,
    (request: services_central_device_service_pb.GetDeviceProvidersRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetDeviceProvidersResponse.deserializeBinary
  );

  getDeviceProviders(
    request: services_central_device_service_pb.GetDeviceProvidersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetDeviceProvidersResponse>;

  getDeviceProviders(
    request: services_central_device_service_pb.GetDeviceProvidersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDeviceProvidersResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetDeviceProvidersResponse>;

  getDeviceProviders(
    request: services_central_device_service_pb.GetDeviceProvidersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDeviceProvidersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetDeviceProviders',
        request,
        metadata || {},
        this.methodInfoGetDeviceProviders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetDeviceProviders',
    request,
    metadata || {},
    this.methodInfoGetDeviceProviders);
  }

  methodInfoGetPartnerDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetPartnerDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetPartnerDevicesRequest,
    services_central_device_service_pb.GetPartnerDevicesResponse,
    (request: services_central_device_service_pb.GetPartnerDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetPartnerDevicesResponse.deserializeBinary
  );

  getPartnerDevices(
    request: services_central_device_service_pb.GetPartnerDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetPartnerDevicesResponse>;

  getPartnerDevices(
    request: services_central_device_service_pb.GetPartnerDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetPartnerDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetPartnerDevicesResponse>;

  getPartnerDevices(
    request: services_central_device_service_pb.GetPartnerDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetPartnerDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetPartnerDevices',
        request,
        metadata || {},
        this.methodInfoGetPartnerDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetPartnerDevices',
    request,
    metadata || {},
    this.methodInfoGetPartnerDevices);
  }

  methodInfoGetMainDevice = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetMainDevice',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetMainDeviceRequest,
    services_central_device_service_pb.GetMainDeviceResponse,
    (request: services_central_device_service_pb.GetMainDeviceRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetMainDeviceResponse.deserializeBinary
  );

  getMainDevice(
    request: services_central_device_service_pb.GetMainDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetMainDeviceResponse>;

  getMainDevice(
    request: services_central_device_service_pb.GetMainDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMainDeviceResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetMainDeviceResponse>;

  getMainDevice(
    request: services_central_device_service_pb.GetMainDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMainDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetMainDevice',
        request,
        metadata || {},
        this.methodInfoGetMainDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetMainDevice',
    request,
    metadata || {},
    this.methodInfoGetMainDevice);
  }

  methodInfoGetSmartMeAuthURL = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetSmartMeAuthURL',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetSmartMeAuthURLRequest,
    services_central_device_service_pb.GetSmartMeAuthURLResponse,
    (request: services_central_device_service_pb.GetSmartMeAuthURLRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetSmartMeAuthURLResponse.deserializeBinary
  );

  getSmartMeAuthURL(
    request: services_central_device_service_pb.GetSmartMeAuthURLRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetSmartMeAuthURLResponse>;

  getSmartMeAuthURL(
    request: services_central_device_service_pb.GetSmartMeAuthURLRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetSmartMeAuthURLResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetSmartMeAuthURLResponse>;

  getSmartMeAuthURL(
    request: services_central_device_service_pb.GetSmartMeAuthURLRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetSmartMeAuthURLResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetSmartMeAuthURL',
        request,
        metadata || {},
        this.methodInfoGetSmartMeAuthURL,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetSmartMeAuthURL',
    request,
    metadata || {},
    this.methodInfoGetSmartMeAuthURL);
  }

  methodInfoConnectSmartMeAccount = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/ConnectSmartMeAccount',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.ConnectSmartMeAccountRequest,
    services_central_device_service_pb.ConnectSmartMeAccountResponse,
    (request: services_central_device_service_pb.ConnectSmartMeAccountRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.ConnectSmartMeAccountResponse.deserializeBinary
  );

  connectSmartMeAccount(
    request: services_central_device_service_pb.ConnectSmartMeAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.ConnectSmartMeAccountResponse>;

  connectSmartMeAccount(
    request: services_central_device_service_pb.ConnectSmartMeAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ConnectSmartMeAccountResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.ConnectSmartMeAccountResponse>;

  connectSmartMeAccount(
    request: services_central_device_service_pb.ConnectSmartMeAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ConnectSmartMeAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/ConnectSmartMeAccount',
        request,
        metadata || {},
        this.methodInfoConnectSmartMeAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/ConnectSmartMeAccount',
    request,
    metadata || {},
    this.methodInfoConnectSmartMeAccount);
  }

  methodInfoGetPowerfoxAuthURL = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetPowerfoxAuthURL',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetPowerfoxAuthURLRequest,
    services_central_device_service_pb.GetPowerfoxAuthURLResponse,
    (request: services_central_device_service_pb.GetPowerfoxAuthURLRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetPowerfoxAuthURLResponse.deserializeBinary
  );

  getPowerfoxAuthURL(
    request: services_central_device_service_pb.GetPowerfoxAuthURLRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetPowerfoxAuthURLResponse>;

  getPowerfoxAuthURL(
    request: services_central_device_service_pb.GetPowerfoxAuthURLRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetPowerfoxAuthURLResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetPowerfoxAuthURLResponse>;

  getPowerfoxAuthURL(
    request: services_central_device_service_pb.GetPowerfoxAuthURLRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetPowerfoxAuthURLResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetPowerfoxAuthURL',
        request,
        metadata || {},
        this.methodInfoGetPowerfoxAuthURL,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetPowerfoxAuthURL',
    request,
    metadata || {},
    this.methodInfoGetPowerfoxAuthURL);
  }

  methodInfoConnectPowerfoxAccount = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/ConnectPowerfoxAccount',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.ConnectPowerfoxAccountRequest,
    services_central_device_service_pb.ConnectPowerfoxAccountResponse,
    (request: services_central_device_service_pb.ConnectPowerfoxAccountRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.ConnectPowerfoxAccountResponse.deserializeBinary
  );

  connectPowerfoxAccount(
    request: services_central_device_service_pb.ConnectPowerfoxAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.ConnectPowerfoxAccountResponse>;

  connectPowerfoxAccount(
    request: services_central_device_service_pb.ConnectPowerfoxAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ConnectPowerfoxAccountResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.ConnectPowerfoxAccountResponse>;

  connectPowerfoxAccount(
    request: services_central_device_service_pb.ConnectPowerfoxAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ConnectPowerfoxAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/ConnectPowerfoxAccount',
        request,
        metadata || {},
        this.methodInfoConnectPowerfoxAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/ConnectPowerfoxAccount',
    request,
    metadata || {},
    this.methodInfoConnectPowerfoxAccount);
  }

  methodInfoGetDeviceReadingStats = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetDeviceReadingStats',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetDeviceReadingStatsRequest,
    services_central_device_service_pb.GetDeviceReadingStatsResponse,
    (request: services_central_device_service_pb.GetDeviceReadingStatsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetDeviceReadingStatsResponse.deserializeBinary
  );

  getDeviceReadingStats(
    request: services_central_device_service_pb.GetDeviceReadingStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetDeviceReadingStatsResponse>;

  getDeviceReadingStats(
    request: services_central_device_service_pb.GetDeviceReadingStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDeviceReadingStatsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetDeviceReadingStatsResponse>;

  getDeviceReadingStats(
    request: services_central_device_service_pb.GetDeviceReadingStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDeviceReadingStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetDeviceReadingStats',
        request,
        metadata || {},
        this.methodInfoGetDeviceReadingStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetDeviceReadingStats',
    request,
    metadata || {},
    this.methodInfoGetDeviceReadingStats);
  }

  methodInfoGetShellyPortalInformation = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetShellyPortalInformation',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetShellyPortalInformationRequest,
    services_central_device_service_pb.GetShellyPortalInformationResponse,
    (request: services_central_device_service_pb.GetShellyPortalInformationRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetShellyPortalInformationResponse.deserializeBinary
  );

  getShellyPortalInformation(
    request: services_central_device_service_pb.GetShellyPortalInformationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetShellyPortalInformationResponse>;

  getShellyPortalInformation(
    request: services_central_device_service_pb.GetShellyPortalInformationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetShellyPortalInformationResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetShellyPortalInformationResponse>;

  getShellyPortalInformation(
    request: services_central_device_service_pb.GetShellyPortalInformationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetShellyPortalInformationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetShellyPortalInformation',
        request,
        metadata || {},
        this.methodInfoGetShellyPortalInformation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetShellyPortalInformation',
    request,
    metadata || {},
    this.methodInfoGetShellyPortalInformation);
  }

  methodInfoGetShellyUserDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetShellyUserDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetShellyUserDevicesRequest,
    services_central_device_service_pb.GetShellyUserDevicesResponse,
    (request: services_central_device_service_pb.GetShellyUserDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetShellyUserDevicesResponse.deserializeBinary
  );

  getShellyUserDevices(
    request: services_central_device_service_pb.GetShellyUserDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetShellyUserDevicesResponse>;

  getShellyUserDevices(
    request: services_central_device_service_pb.GetShellyUserDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetShellyUserDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetShellyUserDevicesResponse>;

  getShellyUserDevices(
    request: services_central_device_service_pb.GetShellyUserDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetShellyUserDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetShellyUserDevices',
        request,
        metadata || {},
        this.methodInfoGetShellyUserDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetShellyUserDevices',
    request,
    metadata || {},
    this.methodInfoGetShellyUserDevices);
  }

  methodInfoUpdateShellyDeviceStatus = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/UpdateShellyDeviceStatus',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.UpdateShellyDeviceStatusRequest,
    services_central_device_service_pb.UpdateShellyDeviceStatusResponse,
    (request: services_central_device_service_pb.UpdateShellyDeviceStatusRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.UpdateShellyDeviceStatusResponse.deserializeBinary
  );

  updateShellyDeviceStatus(
    request: services_central_device_service_pb.UpdateShellyDeviceStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.UpdateShellyDeviceStatusResponse>;

  updateShellyDeviceStatus(
    request: services_central_device_service_pb.UpdateShellyDeviceStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.UpdateShellyDeviceStatusResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.UpdateShellyDeviceStatusResponse>;

  updateShellyDeviceStatus(
    request: services_central_device_service_pb.UpdateShellyDeviceStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.UpdateShellyDeviceStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/UpdateShellyDeviceStatus',
        request,
        metadata || {},
        this.methodInfoUpdateShellyDeviceStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/UpdateShellyDeviceStatus',
    request,
    metadata || {},
    this.methodInfoUpdateShellyDeviceStatus);
  }

  methodInfoGetShellyDeviceDetails = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetShellyDeviceDetails',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetShellyDeviceDetailsRequest,
    services_central_device_service_pb.GetShellyDeviceDetailsResponse,
    (request: services_central_device_service_pb.GetShellyDeviceDetailsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetShellyDeviceDetailsResponse.deserializeBinary
  );

  getShellyDeviceDetails(
    request: services_central_device_service_pb.GetShellyDeviceDetailsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetShellyDeviceDetailsResponse>;

  getShellyDeviceDetails(
    request: services_central_device_service_pb.GetShellyDeviceDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetShellyDeviceDetailsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetShellyDeviceDetailsResponse>;

  getShellyDeviceDetails(
    request: services_central_device_service_pb.GetShellyDeviceDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetShellyDeviceDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetShellyDeviceDetails',
        request,
        metadata || {},
        this.methodInfoGetShellyDeviceDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetShellyDeviceDetails',
    request,
    metadata || {},
    this.methodInfoGetShellyDeviceDetails);
  }

  methodInfoUpdateShellyDeviceChannelDirections = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/UpdateShellyDeviceChannelDirections',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsRequest,
    services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsResponse,
    (request: services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsResponse.deserializeBinary
  );

  updateShellyDeviceChannelDirections(
    request: services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsResponse>;

  updateShellyDeviceChannelDirections(
    request: services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsResponse>;

  updateShellyDeviceChannelDirections(
    request: services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.UpdateShellyDeviceChannelDirectionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/UpdateShellyDeviceChannelDirections',
        request,
        metadata || {},
        this.methodInfoUpdateShellyDeviceChannelDirections,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/UpdateShellyDeviceChannelDirections',
    request,
    metadata || {},
    this.methodInfoUpdateShellyDeviceChannelDirections);
  }

  methodInfoGetBlueMeteringDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetBlueMeteringDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetBlueMeteringDevicesRequest,
    services_central_device_service_pb.GetBlueMeteringDevicesResponse,
    (request: services_central_device_service_pb.GetBlueMeteringDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetBlueMeteringDevicesResponse.deserializeBinary
  );

  getBlueMeteringDevices(
    request: services_central_device_service_pb.GetBlueMeteringDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetBlueMeteringDevicesResponse>;

  getBlueMeteringDevices(
    request: services_central_device_service_pb.GetBlueMeteringDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetBlueMeteringDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetBlueMeteringDevicesResponse>;

  getBlueMeteringDevices(
    request: services_central_device_service_pb.GetBlueMeteringDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetBlueMeteringDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetBlueMeteringDevices',
        request,
        metadata || {},
        this.methodInfoGetBlueMeteringDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetBlueMeteringDevices',
    request,
    metadata || {},
    this.methodInfoGetBlueMeteringDevices);
  }

  methodInfoImportBluemeteringData = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/ImportBluemeteringData',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.ImportBluemeteringDataRequest,
    services_central_device_service_pb.ImportBluemeteringDataResponse,
    (request: services_central_device_service_pb.ImportBluemeteringDataRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.ImportBluemeteringDataResponse.deserializeBinary
  );

  importBluemeteringData(
    request: services_central_device_service_pb.ImportBluemeteringDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.ImportBluemeteringDataResponse>;

  importBluemeteringData(
    request: services_central_device_service_pb.ImportBluemeteringDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ImportBluemeteringDataResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.ImportBluemeteringDataResponse>;

  importBluemeteringData(
    request: services_central_device_service_pb.ImportBluemeteringDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ImportBluemeteringDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/ImportBluemeteringData',
        request,
        metadata || {},
        this.methodInfoImportBluemeteringData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/ImportBluemeteringData',
    request,
    metadata || {},
    this.methodInfoImportBluemeteringData);
  }

  methodInfoGetBluemeteringApiKey = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetBluemeteringApiKey',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetBluemeteringApiKeyRequest,
    services_central_device_service_pb.GetBluemeteringApiKeyResponse,
    (request: services_central_device_service_pb.GetBluemeteringApiKeyRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetBluemeteringApiKeyResponse.deserializeBinary
  );

  getBluemeteringApiKey(
    request: services_central_device_service_pb.GetBluemeteringApiKeyRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetBluemeteringApiKeyResponse>;

  getBluemeteringApiKey(
    request: services_central_device_service_pb.GetBluemeteringApiKeyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetBluemeteringApiKeyResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetBluemeteringApiKeyResponse>;

  getBluemeteringApiKey(
    request: services_central_device_service_pb.GetBluemeteringApiKeyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetBluemeteringApiKeyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetBluemeteringApiKey',
        request,
        metadata || {},
        this.methodInfoGetBluemeteringApiKey,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetBluemeteringApiKey',
    request,
    metadata || {},
    this.methodInfoGetBluemeteringApiKey);
  }

  methodInfoGetMetergridProjects = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetMetergridProjects',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetMetergridProjectsRequest,
    services_central_device_service_pb.GetMetergridProjectsResponse,
    (request: services_central_device_service_pb.GetMetergridProjectsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetMetergridProjectsResponse.deserializeBinary
  );

  getMetergridProjects(
    request: services_central_device_service_pb.GetMetergridProjectsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetMetergridProjectsResponse>;

  getMetergridProjects(
    request: services_central_device_service_pb.GetMetergridProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMetergridProjectsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetMetergridProjectsResponse>;

  getMetergridProjects(
    request: services_central_device_service_pb.GetMetergridProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMetergridProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetMetergridProjects',
        request,
        metadata || {},
        this.methodInfoGetMetergridProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetMetergridProjects',
    request,
    metadata || {},
    this.methodInfoGetMetergridProjects);
  }

  methodInfoGetMetergridTenants = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetMetergridTenants',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetMetergridTenantsRequest,
    services_central_device_service_pb.GetMetergridTenantsResponse,
    (request: services_central_device_service_pb.GetMetergridTenantsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetMetergridTenantsResponse.deserializeBinary
  );

  getMetergridTenants(
    request: services_central_device_service_pb.GetMetergridTenantsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetMetergridTenantsResponse>;

  getMetergridTenants(
    request: services_central_device_service_pb.GetMetergridTenantsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMetergridTenantsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetMetergridTenantsResponse>;

  getMetergridTenants(
    request: services_central_device_service_pb.GetMetergridTenantsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMetergridTenantsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetMetergridTenants',
        request,
        metadata || {},
        this.methodInfoGetMetergridTenants,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetMetergridTenants',
    request,
    metadata || {},
    this.methodInfoGetMetergridTenants);
  }

  methodInfoImportMetergridData = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/ImportMetergridData',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.ImportMetergridDataRequest,
    services_central_device_service_pb.ImportMetergridDataResponse,
    (request: services_central_device_service_pb.ImportMetergridDataRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.ImportMetergridDataResponse.deserializeBinary
  );

  importMetergridData(
    request: services_central_device_service_pb.ImportMetergridDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.ImportMetergridDataResponse>;

  importMetergridData(
    request: services_central_device_service_pb.ImportMetergridDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ImportMetergridDataResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.ImportMetergridDataResponse>;

  importMetergridData(
    request: services_central_device_service_pb.ImportMetergridDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ImportMetergridDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/ImportMetergridData',
        request,
        metadata || {},
        this.methodInfoImportMetergridData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/ImportMetergridData',
    request,
    metadata || {},
    this.methodInfoImportMetergridData);
  }

  methodInfoImportReadings = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/ImportReadings',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.ImportReadingsRequest,
    services_central_device_service_pb.ImportReadingsResponse,
    (request: services_central_device_service_pb.ImportReadingsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.ImportReadingsResponse.deserializeBinary
  );

  importReadings(
    request: services_central_device_service_pb.ImportReadingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.ImportReadingsResponse>;

  importReadings(
    request: services_central_device_service_pb.ImportReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ImportReadingsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.ImportReadingsResponse>;

  importReadings(
    request: services_central_device_service_pb.ImportReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.ImportReadingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/ImportReadings',
        request,
        metadata || {},
        this.methodInfoImportReadings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/ImportReadings',
    request,
    metadata || {},
    this.methodInfoImportReadings);
  }

  methodInfoGetDataImports = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetDataImports',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetDataImportsRequest,
    services_central_device_service_pb.GetDataImportsResponse,
    (request: services_central_device_service_pb.GetDataImportsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetDataImportsResponse.deserializeBinary
  );

  getDataImports(
    request: services_central_device_service_pb.GetDataImportsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetDataImportsResponse>;

  getDataImports(
    request: services_central_device_service_pb.GetDataImportsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDataImportsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetDataImportsResponse>;

  getDataImports(
    request: services_central_device_service_pb.GetDataImportsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetDataImportsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetDataImports',
        request,
        metadata || {},
        this.methodInfoGetDataImports,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetDataImports',
    request,
    metadata || {},
    this.methodInfoGetDataImports);
  }

  methodInfoStoreImovisReadings = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/StoreImovisReadings',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.StoreImovisReadingsRequest,
    services_central_device_service_pb.StoreImovisReadingsResponse,
    (request: services_central_device_service_pb.StoreImovisReadingsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.StoreImovisReadingsResponse.deserializeBinary
  );

  storeImovisReadings(
    request: services_central_device_service_pb.StoreImovisReadingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.StoreImovisReadingsResponse>;

  storeImovisReadings(
    request: services_central_device_service_pb.StoreImovisReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.StoreImovisReadingsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.StoreImovisReadingsResponse>;

  storeImovisReadings(
    request: services_central_device_service_pb.StoreImovisReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.StoreImovisReadingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/StoreImovisReadings',
        request,
        metadata || {},
        this.methodInfoStoreImovisReadings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/StoreImovisReadings',
    request,
    metadata || {},
    this.methodInfoStoreImovisReadings);
  }

  methodInfoGetMyStromDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetMyStromDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetMyStromDevicesRequest,
    services_central_device_service_pb.GetMyStromDevicesResponse,
    (request: services_central_device_service_pb.GetMyStromDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetMyStromDevicesResponse.deserializeBinary
  );

  getMyStromDevices(
    request: services_central_device_service_pb.GetMyStromDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetMyStromDevicesResponse>;

  getMyStromDevices(
    request: services_central_device_service_pb.GetMyStromDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMyStromDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetMyStromDevicesResponse>;

  getMyStromDevices(
    request: services_central_device_service_pb.GetMyStromDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMyStromDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetMyStromDevices',
        request,
        metadata || {},
        this.methodInfoGetMyStromDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetMyStromDevices',
    request,
    metadata || {},
    this.methodInfoGetMyStromDevices);
  }

  methodInfoGetTsunDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetTsunDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetTsunDevicesRequest,
    services_central_device_service_pb.GetTsunDevicesResponse,
    (request: services_central_device_service_pb.GetTsunDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetTsunDevicesResponse.deserializeBinary
  );

  getTsunDevices(
    request: services_central_device_service_pb.GetTsunDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetTsunDevicesResponse>;

  getTsunDevices(
    request: services_central_device_service_pb.GetTsunDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetTsunDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetTsunDevicesResponse>;

  getTsunDevices(
    request: services_central_device_service_pb.GetTsunDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetTsunDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetTsunDevices',
        request,
        metadata || {},
        this.methodInfoGetTsunDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetTsunDevices',
    request,
    metadata || {},
    this.methodInfoGetTsunDevices);
  }

  methodInfoStoreNonSmartMeterReadings = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/StoreNonSmartMeterReadings',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.StoreNonSmartMeterReadingsRequest,
    services_central_device_service_pb.StoreNonSmartMeterReadingsResponse,
    (request: services_central_device_service_pb.StoreNonSmartMeterReadingsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.StoreNonSmartMeterReadingsResponse.deserializeBinary
  );

  storeNonSmartMeterReadings(
    request: services_central_device_service_pb.StoreNonSmartMeterReadingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.StoreNonSmartMeterReadingsResponse>;

  storeNonSmartMeterReadings(
    request: services_central_device_service_pb.StoreNonSmartMeterReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.StoreNonSmartMeterReadingsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.StoreNonSmartMeterReadingsResponse>;

  storeNonSmartMeterReadings(
    request: services_central_device_service_pb.StoreNonSmartMeterReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.StoreNonSmartMeterReadingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/StoreNonSmartMeterReadings',
        request,
        metadata || {},
        this.methodInfoStoreNonSmartMeterReadings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/StoreNonSmartMeterReadings',
    request,
    metadata || {},
    this.methodInfoStoreNonSmartMeterReadings);
  }

  methodInfoGetViessmannAuthConfig = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetViessmannAuthConfig',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetViessmannAuthConfigRequest,
    services_central_device_service_pb.GetViessmannAuthConfigResponse,
    (request: services_central_device_service_pb.GetViessmannAuthConfigRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetViessmannAuthConfigResponse.deserializeBinary
  );

  getViessmannAuthConfig(
    request: services_central_device_service_pb.GetViessmannAuthConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetViessmannAuthConfigResponse>;

  getViessmannAuthConfig(
    request: services_central_device_service_pb.GetViessmannAuthConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetViessmannAuthConfigResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetViessmannAuthConfigResponse>;

  getViessmannAuthConfig(
    request: services_central_device_service_pb.GetViessmannAuthConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetViessmannAuthConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetViessmannAuthConfig',
        request,
        metadata || {},
        this.methodInfoGetViessmannAuthConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetViessmannAuthConfig',
    request,
    metadata || {},
    this.methodInfoGetViessmannAuthConfig);
  }

  methodInfoGetAvailableViessmannDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetAvailableViessmannDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetAvailableViessmannDevicesRequest,
    services_central_device_service_pb.GetAvailableViessmannDevicesResponse,
    (request: services_central_device_service_pb.GetAvailableViessmannDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetAvailableViessmannDevicesResponse.deserializeBinary
  );

  getAvailableViessmannDevices(
    request: services_central_device_service_pb.GetAvailableViessmannDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetAvailableViessmannDevicesResponse>;

  getAvailableViessmannDevices(
    request: services_central_device_service_pb.GetAvailableViessmannDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetAvailableViessmannDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetAvailableViessmannDevicesResponse>;

  getAvailableViessmannDevices(
    request: services_central_device_service_pb.GetAvailableViessmannDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetAvailableViessmannDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetAvailableViessmannDevices',
        request,
        metadata || {},
        this.methodInfoGetAvailableViessmannDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetAvailableViessmannDevices',
    request,
    metadata || {},
    this.methodInfoGetAvailableViessmannDevices);
  }

  methodInfoDeleteViessmannClientInfo = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/DeleteViessmannClientInfo',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.DeleteViessmannClientInfoRequest,
    services_central_device_service_pb.DeleteViessmannClientInfoResponse,
    (request: services_central_device_service_pb.DeleteViessmannClientInfoRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.DeleteViessmannClientInfoResponse.deserializeBinary
  );

  deleteViessmannClientInfo(
    request: services_central_device_service_pb.DeleteViessmannClientInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.DeleteViessmannClientInfoResponse>;

  deleteViessmannClientInfo(
    request: services_central_device_service_pb.DeleteViessmannClientInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.DeleteViessmannClientInfoResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.DeleteViessmannClientInfoResponse>;

  deleteViessmannClientInfo(
    request: services_central_device_service_pb.DeleteViessmannClientInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.DeleteViessmannClientInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/DeleteViessmannClientInfo',
        request,
        metadata || {},
        this.methodInfoDeleteViessmannClientInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/DeleteViessmannClientInfo',
    request,
    metadata || {},
    this.methodInfoDeleteViessmannClientInfo);
  }

  methodInfoStoreReadings = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/StoreReadings',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.StoreReadingsRequest,
    services_central_device_service_pb.StoreReadingsResponse,
    (request: services_central_device_service_pb.StoreReadingsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.StoreReadingsResponse.deserializeBinary
  );

  storeReadings(
    request: services_central_device_service_pb.StoreReadingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.StoreReadingsResponse>;

  storeReadings(
    request: services_central_device_service_pb.StoreReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.StoreReadingsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.StoreReadingsResponse>;

  storeReadings(
    request: services_central_device_service_pb.StoreReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.StoreReadingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/StoreReadings',
        request,
        metadata || {},
        this.methodInfoStoreReadings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/StoreReadings',
    request,
    metadata || {},
    this.methodInfoStoreReadings);
  }

  methodInfoAddAutomation = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/AddAutomation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.AddAutomationRequest,
    services_central_advisory_service_pb.AddAutomationResponse,
    (request: services_central_advisory_service_pb.AddAutomationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.AddAutomationResponse.deserializeBinary
  );

  addAutomation(
    request: services_central_advisory_service_pb.AddAutomationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.AddAutomationResponse>;

  addAutomation(
    request: services_central_advisory_service_pb.AddAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.AddAutomationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.AddAutomationResponse>;

  addAutomation(
    request: services_central_advisory_service_pb.AddAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.AddAutomationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/AddAutomation',
        request,
        metadata || {},
        this.methodInfoAddAutomation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/AddAutomation',
    request,
    metadata || {},
    this.methodInfoAddAutomation);
  }

  methodInfoDeleteAutomation = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/DeleteAutomation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.DeleteAutomationRequest,
    services_central_advisory_service_pb.DeleteAutomationResponse,
    (request: services_central_advisory_service_pb.DeleteAutomationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.DeleteAutomationResponse.deserializeBinary
  );

  deleteAutomation(
    request: services_central_advisory_service_pb.DeleteAutomationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.DeleteAutomationResponse>;

  deleteAutomation(
    request: services_central_advisory_service_pb.DeleteAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.DeleteAutomationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.DeleteAutomationResponse>;

  deleteAutomation(
    request: services_central_advisory_service_pb.DeleteAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.DeleteAutomationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/DeleteAutomation',
        request,
        metadata || {},
        this.methodInfoDeleteAutomation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/DeleteAutomation',
    request,
    metadata || {},
    this.methodInfoDeleteAutomation);
  }

  methodInfoGetAutomations = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetAutomations',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetAutomationsRequest,
    services_central_advisory_service_pb.GetAutomationsResponse,
    (request: services_central_advisory_service_pb.GetAutomationsRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetAutomationsResponse.deserializeBinary
  );

  getAutomations(
    request: services_central_advisory_service_pb.GetAutomationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetAutomationsResponse>;

  getAutomations(
    request: services_central_advisory_service_pb.GetAutomationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetAutomationsResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetAutomationsResponse>;

  getAutomations(
    request: services_central_advisory_service_pb.GetAutomationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetAutomationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetAutomations',
        request,
        metadata || {},
        this.methodInfoGetAutomations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetAutomations',
    request,
    metadata || {},
    this.methodInfoGetAutomations);
  }

  methodInfoGetDispatchedActions = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetDispatchedActions',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetDispatchedActionsRequest,
    services_central_advisory_service_pb.GetDispatchedActionsResponse,
    (request: services_central_advisory_service_pb.GetDispatchedActionsRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetDispatchedActionsResponse.deserializeBinary
  );

  getDispatchedActions(
    request: services_central_advisory_service_pb.GetDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetDispatchedActionsResponse>;

  getDispatchedActions(
    request: services_central_advisory_service_pb.GetDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetDispatchedActionsResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetDispatchedActionsResponse>;

  getDispatchedActions(
    request: services_central_advisory_service_pb.GetDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetDispatchedActionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetDispatchedActions',
        request,
        metadata || {},
        this.methodInfoGetDispatchedActions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetDispatchedActions',
    request,
    metadata || {},
    this.methodInfoGetDispatchedActions);
  }

  methodInfoRetryDispatchedAction = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/RetryDispatchedAction',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.RetryDispatchedActionRequest,
    services_central_advisory_service_pb.RetryDispatchedActionResponse,
    (request: services_central_advisory_service_pb.RetryDispatchedActionRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.RetryDispatchedActionResponse.deserializeBinary
  );

  retryDispatchedAction(
    request: services_central_advisory_service_pb.RetryDispatchedActionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.RetryDispatchedActionResponse>;

  retryDispatchedAction(
    request: services_central_advisory_service_pb.RetryDispatchedActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.RetryDispatchedActionResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.RetryDispatchedActionResponse>;

  retryDispatchedAction(
    request: services_central_advisory_service_pb.RetryDispatchedActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.RetryDispatchedActionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/RetryDispatchedAction',
        request,
        metadata || {},
        this.methodInfoRetryDispatchedAction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/RetryDispatchedAction',
    request,
    metadata || {},
    this.methodInfoRetryDispatchedAction);
  }

  methodInfoGetTibberAuthURL = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetTibberAuthURL',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetTibberAuthURLRequest,
    services_central_device_service_pb.GetTibberAuthURLResponse,
    (request: services_central_device_service_pb.GetTibberAuthURLRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetTibberAuthURLResponse.deserializeBinary
  );

  getTibberAuthURL(
    request: services_central_device_service_pb.GetTibberAuthURLRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetTibberAuthURLResponse>;

  getTibberAuthURL(
    request: services_central_device_service_pb.GetTibberAuthURLRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetTibberAuthURLResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetTibberAuthURLResponse>;

  getTibberAuthURL(
    request: services_central_device_service_pb.GetTibberAuthURLRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetTibberAuthURLResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetTibberAuthURL',
        request,
        metadata || {},
        this.methodInfoGetTibberAuthURL,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetTibberAuthURL',
    request,
    metadata || {},
    this.methodInfoGetTibberAuthURL);
  }

  methodInfoGetTibberHomes = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetTibberHomes',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetTibberHomesRequest,
    services_central_device_service_pb.GetTibberHomesResponse,
    (request: services_central_device_service_pb.GetTibberHomesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetTibberHomesResponse.deserializeBinary
  );

  getTibberHomes(
    request: services_central_device_service_pb.GetTibberHomesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetTibberHomesResponse>;

  getTibberHomes(
    request: services_central_device_service_pb.GetTibberHomesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetTibberHomesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetTibberHomesResponse>;

  getTibberHomes(
    request: services_central_device_service_pb.GetTibberHomesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetTibberHomesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetTibberHomes',
        request,
        metadata || {},
        this.methodInfoGetTibberHomes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetTibberHomes',
    request,
    metadata || {},
    this.methodInfoGetTibberHomes);
  }

  methodInfoGetTibberAuthToken = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetTibberAuthToken',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetTibberAuthTokenRequest,
    services_central_device_service_pb.GetTibberAuthTokenResponse,
    (request: services_central_device_service_pb.GetTibberAuthTokenRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetTibberAuthTokenResponse.deserializeBinary
  );

  getTibberAuthToken(
    request: services_central_device_service_pb.GetTibberAuthTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetTibberAuthTokenResponse>;

  getTibberAuthToken(
    request: services_central_device_service_pb.GetTibberAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetTibberAuthTokenResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetTibberAuthTokenResponse>;

  getTibberAuthToken(
    request: services_central_device_service_pb.GetTibberAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetTibberAuthTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetTibberAuthToken',
        request,
        metadata || {},
        this.methodInfoGetTibberAuthToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetTibberAuthToken',
    request,
    metadata || {},
    this.methodInfoGetTibberAuthToken);
  }

  methodInfoGetLobaroDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetLobaroDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetLobaroDevicesRequest,
    services_central_device_service_pb.GetLobaroDevicesResponse,
    (request: services_central_device_service_pb.GetLobaroDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetLobaroDevicesResponse.deserializeBinary
  );

  getLobaroDevices(
    request: services_central_device_service_pb.GetLobaroDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetLobaroDevicesResponse>;

  getLobaroDevices(
    request: services_central_device_service_pb.GetLobaroDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetLobaroDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetLobaroDevicesResponse>;

  getLobaroDevices(
    request: services_central_device_service_pb.GetLobaroDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetLobaroDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetLobaroDevices',
        request,
        metadata || {},
        this.methodInfoGetLobaroDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetLobaroDevices',
    request,
    metadata || {},
    this.methodInfoGetLobaroDevices);
  }

  methodInfoGetSolarEdgeSites = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetSolarEdgeSites',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetSolarEdgeSitesRequest,
    services_central_device_service_pb.GetSolarEdgeSitesResponse,
    (request: services_central_device_service_pb.GetSolarEdgeSitesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetSolarEdgeSitesResponse.deserializeBinary
  );

  getSolarEdgeSites(
    request: services_central_device_service_pb.GetSolarEdgeSitesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetSolarEdgeSitesResponse>;

  getSolarEdgeSites(
    request: services_central_device_service_pb.GetSolarEdgeSitesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetSolarEdgeSitesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetSolarEdgeSitesResponse>;

  getSolarEdgeSites(
    request: services_central_device_service_pb.GetSolarEdgeSitesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetSolarEdgeSitesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetSolarEdgeSites',
        request,
        metadata || {},
        this.methodInfoGetSolarEdgeSites,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetSolarEdgeSites',
    request,
    metadata || {},
    this.methodInfoGetSolarEdgeSites);
  }

  methodInfoGetSolarEdgeDevices = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetSolarEdgeDevices',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetSolarEdgeDevicesRequest,
    services_central_device_service_pb.GetSolarEdgeDevicesResponse,
    (request: services_central_device_service_pb.GetSolarEdgeDevicesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetSolarEdgeDevicesResponse.deserializeBinary
  );

  getSolarEdgeDevices(
    request: services_central_device_service_pb.GetSolarEdgeDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetSolarEdgeDevicesResponse>;

  getSolarEdgeDevices(
    request: services_central_device_service_pb.GetSolarEdgeDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetSolarEdgeDevicesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetSolarEdgeDevicesResponse>;

  getSolarEdgeDevices(
    request: services_central_device_service_pb.GetSolarEdgeDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetSolarEdgeDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetSolarEdgeDevices',
        request,
        metadata || {},
        this.methodInfoGetSolarEdgeDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetSolarEdgeDevices',
    request,
    metadata || {},
    this.methodInfoGetSolarEdgeDevices);
  }

  methodInfoGetVirtualDevice = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetVirtualDevice',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetVirtualDeviceRequest,
    services_central_device_service_pb.GetVirtualDeviceResponse,
    (request: services_central_device_service_pb.GetVirtualDeviceRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetVirtualDeviceResponse.deserializeBinary
  );

  getVirtualDevice(
    request: services_central_device_service_pb.GetVirtualDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetVirtualDeviceResponse>;

  getVirtualDevice(
    request: services_central_device_service_pb.GetVirtualDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetVirtualDeviceResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetVirtualDeviceResponse>;

  getVirtualDevice(
    request: services_central_device_service_pb.GetVirtualDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetVirtualDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetVirtualDevice',
        request,
        metadata || {},
        this.methodInfoGetVirtualDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetVirtualDevice',
    request,
    metadata || {},
    this.methodInfoGetVirtualDevice);
  }

  methodInfoGetMetiundoMeteringPoints = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetMetiundoMeteringPoints',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetMetiundoMeteringPointsRequest,
    services_central_device_service_pb.GetMetiundoMeteringPointsResponse,
    (request: services_central_device_service_pb.GetMetiundoMeteringPointsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetMetiundoMeteringPointsResponse.deserializeBinary
  );

  getMetiundoMeteringPoints(
    request: services_central_device_service_pb.GetMetiundoMeteringPointsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetMetiundoMeteringPointsResponse>;

  getMetiundoMeteringPoints(
    request: services_central_device_service_pb.GetMetiundoMeteringPointsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMetiundoMeteringPointsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetMetiundoMeteringPointsResponse>;

  getMetiundoMeteringPoints(
    request: services_central_device_service_pb.GetMetiundoMeteringPointsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMetiundoMeteringPointsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetMetiundoMeteringPoints',
        request,
        metadata || {},
        this.methodInfoGetMetiundoMeteringPoints,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetMetiundoMeteringPoints',
    request,
    metadata || {},
    this.methodInfoGetMetiundoMeteringPoints);
  }

  methodInfoGetBitShakeMeterScript = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetBitShakeMeterScript',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetBitShakeMeterScriptRequest,
    services_central_device_service_pb.GetBitShakeMeterScriptResponse,
    (request: services_central_device_service_pb.GetBitShakeMeterScriptRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetBitShakeMeterScriptResponse.deserializeBinary
  );

  getBitShakeMeterScript(
    request: services_central_device_service_pb.GetBitShakeMeterScriptRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetBitShakeMeterScriptResponse>;

  getBitShakeMeterScript(
    request: services_central_device_service_pb.GetBitShakeMeterScriptRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetBitShakeMeterScriptResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetBitShakeMeterScriptResponse>;

  getBitShakeMeterScript(
    request: services_central_device_service_pb.GetBitShakeMeterScriptRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetBitShakeMeterScriptResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetBitShakeMeterScript',
        request,
        metadata || {},
        this.methodInfoGetBitShakeMeterScript,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetBitShakeMeterScript',
    request,
    metadata || {},
    this.methodInfoGetBitShakeMeterScript);
  }

  methodInfoGetBitShakeSupportedMeters = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetBitShakeSupportedMeters',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetBitShakeSupportedMetersRequest,
    services_central_device_service_pb.GetBitShakeSupportedMetersResponse,
    (request: services_central_device_service_pb.GetBitShakeSupportedMetersRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetBitShakeSupportedMetersResponse.deserializeBinary
  );

  getBitShakeSupportedMeters(
    request: services_central_device_service_pb.GetBitShakeSupportedMetersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetBitShakeSupportedMetersResponse>;

  getBitShakeSupportedMeters(
    request: services_central_device_service_pb.GetBitShakeSupportedMetersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetBitShakeSupportedMetersResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetBitShakeSupportedMetersResponse>;

  getBitShakeSupportedMeters(
    request: services_central_device_service_pb.GetBitShakeSupportedMetersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetBitShakeSupportedMetersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetBitShakeSupportedMeters',
        request,
        metadata || {},
        this.methodInfoGetBitShakeSupportedMeters,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetBitShakeSupportedMeters',
    request,
    metadata || {},
    this.methodInfoGetBitShakeSupportedMeters);
  }

  methodInfoGetIometerQRCode = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetIometerQRCode',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetIometerQRCodeRequest,
    services_central_device_service_pb.GetIometerQRCodeResponse,
    (request: services_central_device_service_pb.GetIometerQRCodeRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetIometerQRCodeResponse.deserializeBinary
  );

  getIometerQRCode(
    request: services_central_device_service_pb.GetIometerQRCodeRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetIometerQRCodeResponse>;

  getIometerQRCode(
    request: services_central_device_service_pb.GetIometerQRCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetIometerQRCodeResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetIometerQRCodeResponse>;

  getIometerQRCode(
    request: services_central_device_service_pb.GetIometerQRCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetIometerQRCodeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetIometerQRCode',
        request,
        metadata || {},
        this.methodInfoGetIometerQRCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetIometerQRCode',
    request,
    metadata || {},
    this.methodInfoGetIometerQRCode);
  }

  methodInfoGetMeteocontrolSystems = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetMeteocontrolSystems',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetMeteocontrolSystemsRequest,
    services_central_device_service_pb.GetMeteocontrolSystemsResponse,
    (request: services_central_device_service_pb.GetMeteocontrolSystemsRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetMeteocontrolSystemsResponse.deserializeBinary
  );

  getMeteocontrolSystems(
    request: services_central_device_service_pb.GetMeteocontrolSystemsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetMeteocontrolSystemsResponse>;

  getMeteocontrolSystems(
    request: services_central_device_service_pb.GetMeteocontrolSystemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMeteocontrolSystemsResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetMeteocontrolSystemsResponse>;

  getMeteocontrolSystems(
    request: services_central_device_service_pb.GetMeteocontrolSystemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMeteocontrolSystemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetMeteocontrolSystems',
        request,
        metadata || {},
        this.methodInfoGetMeteocontrolSystems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetMeteocontrolSystems',
    request,
    metadata || {},
    this.methodInfoGetMeteocontrolSystems);
  }

  methodInfoGetMeteocontrolInverters = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetMeteocontrolInverters',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetMeteocontrolInvertersRequest,
    services_central_device_service_pb.GetMeteocontrolInvertersResponse,
    (request: services_central_device_service_pb.GetMeteocontrolInvertersRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetMeteocontrolInvertersResponse.deserializeBinary
  );

  getMeteocontrolInverters(
    request: services_central_device_service_pb.GetMeteocontrolInvertersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetMeteocontrolInvertersResponse>;

  getMeteocontrolInverters(
    request: services_central_device_service_pb.GetMeteocontrolInvertersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMeteocontrolInvertersResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetMeteocontrolInvertersResponse>;

  getMeteocontrolInverters(
    request: services_central_device_service_pb.GetMeteocontrolInvertersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetMeteocontrolInvertersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetMeteocontrolInverters',
        request,
        metadata || {},
        this.methodInfoGetMeteocontrolInverters,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetMeteocontrolInverters',
    request,
    metadata || {},
    this.methodInfoGetMeteocontrolInverters);
  }

  methodInfoGetExportDataTypes = new grpcWeb.MethodDescriptor(
    '/services.central.DeviceService/GetExportDataTypes',
    grpcWeb.MethodType.UNARY,
    services_central_device_service_pb.GetExportDataTypesRequest,
    services_central_device_service_pb.GetExportDataTypesResponse,
    (request: services_central_device_service_pb.GetExportDataTypesRequest) => {
      return request.serializeBinary();
    },
    services_central_device_service_pb.GetExportDataTypesResponse.deserializeBinary
  );

  getExportDataTypes(
    request: services_central_device_service_pb.GetExportDataTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_device_service_pb.GetExportDataTypesResponse>;

  getExportDataTypes(
    request: services_central_device_service_pb.GetExportDataTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetExportDataTypesResponse) => void): grpcWeb.ClientReadableStream<services_central_device_service_pb.GetExportDataTypesResponse>;

  getExportDataTypes(
    request: services_central_device_service_pb.GetExportDataTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_device_service_pb.GetExportDataTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.DeviceService/GetExportDataTypes',
        request,
        metadata || {},
        this.methodInfoGetExportDataTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.DeviceService/GetExportDataTypes',
    request,
    metadata || {},
    this.methodInfoGetExportDataTypes);
  }

}

