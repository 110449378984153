// source: types/device_registration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_pb = require('../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
var types_device_reading_pb = require('../types/device_reading_pb.js');
goog.object.extend(proto, types_device_reading_pb);
var types_space_pb = require('../types/space_pb.js');
goog.object.extend(proto, types_space_pb);
goog.exportSymbol('proto.types.AuxiliusRegistrationDetails', null, global);
goog.exportSymbol('proto.types.BSEDRegistrationDetails', null, global);
goog.exportSymbol('proto.types.BitShakeRegistrationDetails', null, global);
goog.exportSymbol('proto.types.BlueMeteringRegistrationDetails', null, global);
goog.exportSymbol('proto.types.ChargecloudRegistrationDetails', null, global);
goog.exportSymbol('proto.types.DeviceRegistration', null, global);
goog.exportSymbol('proto.types.DeviceRegistration.ProvderDetailsCase', null, global);
goog.exportSymbol('proto.types.DeviceRegistration.SpaceMapping', null, global);
goog.exportSymbol('proto.types.DiscovergyRegistrationDetails', null, global);
goog.exportSymbol('proto.types.EaseeRegistrationDetails', null, global);
goog.exportSymbol('proto.types.EdaRegistrationDetails', null, global);
goog.exportSymbol('proto.types.EnbwRegistrationDetails', null, global);
goog.exportSymbol('proto.types.EnbwRegistrationDetails.LocationIdCase', null, global);
goog.exportSymbol('proto.types.ExternalRegistrationDetails', null, global);
goog.exportSymbol('proto.types.GoERegistrationDetails', null, global);
goog.exportSymbol('proto.types.GoERegistrationDetails.APIVersion', null, global);
goog.exportSymbol('proto.types.IoMeterRegistrationDetails', null, global);
goog.exportSymbol('proto.types.LobaroRegistrationDetails', null, global);
goog.exportSymbol('proto.types.LobaroRegistrationDetails.DeviceDetails', null, global);
goog.exportSymbol('proto.types.MeteocontrolRegistrationDetails', null, global);
goog.exportSymbol('proto.types.MetiundoLoginDetails', null, global);
goog.exportSymbol('proto.types.MetiundoMeteringPoint', null, global);
goog.exportSymbol('proto.types.MetiundoRegistrationDetails', null, global);
goog.exportSymbol('proto.types.MsconsRegistrationDetails', null, global);
goog.exportSymbol('proto.types.MsconsRegistrationDetails.LocationIdType', null, global);
goog.exportSymbol('proto.types.MyStromRegistrationDetails', null, global);
goog.exportSymbol('proto.types.NonSmartMeterRegistrationDetails', null, global);
goog.exportSymbol('proto.types.OcppRegistrationDetails', null, global);
goog.exportSymbol('proto.types.ShellyRegistrationDetails', null, global);
goog.exportSymbol('proto.types.SmartMeRegistrationDetails', null, global);
goog.exportSymbol('proto.types.SolarEdgeRegistrationDetails', null, global);
goog.exportSymbol('proto.types.SolarEdgeRegistrationDetails.DeviceData', null, global);
goog.exportSymbol('proto.types.TibberRegistrationDetails', null, global);
goog.exportSymbol('proto.types.TsunRegistrationDetails', null, global);
goog.exportSymbol('proto.types.ViessmannRegistrationDetails', null, global);
goog.exportSymbol('proto.types.VirtualDeviceRegistrationDetails', null, global);
goog.exportSymbol('proto.types.WattlineRegistrationDetails', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceRegistration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.DeviceRegistration.oneofGroups_);
};
goog.inherits(proto.types.DeviceRegistration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceRegistration.displayName = 'proto.types.DeviceRegistration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceRegistration.SpaceMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DeviceRegistration.SpaceMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceRegistration.SpaceMapping.displayName = 'proto.types.DeviceRegistration.SpaceMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DiscovergyRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DiscovergyRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DiscovergyRegistrationDetails.displayName = 'proto.types.DiscovergyRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.BlueMeteringRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.BlueMeteringRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.BlueMeteringRegistrationDetails.displayName = 'proto.types.BlueMeteringRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.BSEDRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.BSEDRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.BSEDRegistrationDetails.displayName = 'proto.types.BSEDRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SmartMeRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SmartMeRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SmartMeRegistrationDetails.displayName = 'proto.types.SmartMeRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.EaseeRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.EaseeRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.EaseeRegistrationDetails.displayName = 'proto.types.EaseeRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ShellyRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.ShellyRegistrationDetails.repeatedFields_, null);
};
goog.inherits(proto.types.ShellyRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ShellyRegistrationDetails.displayName = 'proto.types.ShellyRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.WattlineRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.WattlineRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.WattlineRegistrationDetails.displayName = 'proto.types.WattlineRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MsconsRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.MsconsRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MsconsRegistrationDetails.displayName = 'proto.types.MsconsRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MyStromRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.MyStromRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MyStromRegistrationDetails.displayName = 'proto.types.MyStromRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TsunRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TsunRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TsunRegistrationDetails.displayName = 'proto.types.TsunRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.GoERegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.GoERegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.GoERegistrationDetails.displayName = 'proto.types.GoERegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.NonSmartMeterRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.NonSmartMeterRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.NonSmartMeterRegistrationDetails.displayName = 'proto.types.NonSmartMeterRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ViessmannRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.ViessmannRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ViessmannRegistrationDetails.displayName = 'proto.types.ViessmannRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ExternalRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.ExternalRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ExternalRegistrationDetails.displayName = 'proto.types.ExternalRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.EdaRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.EdaRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.EdaRegistrationDetails.displayName = 'proto.types.EdaRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.AuxiliusRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.AuxiliusRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.AuxiliusRegistrationDetails.displayName = 'proto.types.AuxiliusRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TibberRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TibberRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TibberRegistrationDetails.displayName = 'proto.types.TibberRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LobaroRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.LobaroRegistrationDetails.repeatedFields_, null);
};
goog.inherits(proto.types.LobaroRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LobaroRegistrationDetails.displayName = 'proto.types.LobaroRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LobaroRegistrationDetails.DeviceDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.LobaroRegistrationDetails.DeviceDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LobaroRegistrationDetails.DeviceDetails.displayName = 'proto.types.LobaroRegistrationDetails.DeviceDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.OcppRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.OcppRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.OcppRegistrationDetails.displayName = 'proto.types.OcppRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.VirtualDeviceRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.VirtualDeviceRegistrationDetails.repeatedFields_, null);
};
goog.inherits(proto.types.VirtualDeviceRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.VirtualDeviceRegistrationDetails.displayName = 'proto.types.VirtualDeviceRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SolarEdgeRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.SolarEdgeRegistrationDetails.repeatedFields_, null);
};
goog.inherits(proto.types.SolarEdgeRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SolarEdgeRegistrationDetails.displayName = 'proto.types.SolarEdgeRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SolarEdgeRegistrationDetails.DeviceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SolarEdgeRegistrationDetails.DeviceData.displayName = 'proto.types.SolarEdgeRegistrationDetails.DeviceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MetiundoRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.MetiundoRegistrationDetails.repeatedFields_, null);
};
goog.inherits(proto.types.MetiundoRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MetiundoRegistrationDetails.displayName = 'proto.types.MetiundoRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MetiundoLoginDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.MetiundoLoginDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MetiundoLoginDetails.displayName = 'proto.types.MetiundoLoginDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MetiundoMeteringPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.MetiundoMeteringPoint.repeatedFields_, null);
};
goog.inherits(proto.types.MetiundoMeteringPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MetiundoMeteringPoint.displayName = 'proto.types.MetiundoMeteringPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.EnbwRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.EnbwRegistrationDetails.oneofGroups_);
};
goog.inherits(proto.types.EnbwRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.EnbwRegistrationDetails.displayName = 'proto.types.EnbwRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.BitShakeRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.BitShakeRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.BitShakeRegistrationDetails.displayName = 'proto.types.BitShakeRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.IoMeterRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.IoMeterRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.IoMeterRegistrationDetails.displayName = 'proto.types.IoMeterRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MeteocontrolRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.MeteocontrolRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MeteocontrolRegistrationDetails.displayName = 'proto.types.MeteocontrolRegistrationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ChargecloudRegistrationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.ChargecloudRegistrationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ChargecloudRegistrationDetails.displayName = 'proto.types.ChargecloudRegistrationDetails';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.DeviceRegistration.oneofGroups_ = [[6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,29,30,31,32,33]];

/**
 * @enum {number}
 */
proto.types.DeviceRegistration.ProvderDetailsCase = {
  PROVDER_DETAILS_NOT_SET: 0,
  DISCOVERGY: 6,
  BLUEMETERING: 7,
  BSED: 8,
  SMARTME: 9,
  EASEE: 10,
  SHELLY: 11,
  WATTLINE: 12,
  MSCONS: 13,
  NON_SMART_METER: 14,
  GO_E: 15,
  MYSTROM: 16,
  VIESSMANN: 17,
  TSUN: 18,
  EXTERNAL: 19,
  EDA: 20,
  AUXILIUS: 21,
  TIBBER: 22,
  OCPP: 23,
  LOBARO: 24,
  VIRTUAL_DEVICE: 25,
  SOLAREDGE: 26,
  METIUNDO: 27,
  ENBW: 29,
  BITSHAKE: 30,
  IOMETER: 31,
  METEOCONTROL: 32,
  CHARGECLOUD: 33
};

/**
 * @return {proto.types.DeviceRegistration.ProvderDetailsCase}
 */
proto.types.DeviceRegistration.prototype.getProvderDetailsCase = function() {
  return /** @type {proto.types.DeviceRegistration.ProvderDetailsCase} */(jspb.Message.computeOneofCase(this, proto.types.DeviceRegistration.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceRegistration.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceRegistration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceRegistration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceRegistration.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceMapping: (f = msg.getSpaceMapping()) && proto.types.DeviceRegistration.SpaceMapping.toObject(includeInstance, f),
    deviceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deviceProvider: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deviceMedium: jspb.Message.getFieldWithDefault(msg, 5, 0),
    partnerId: jspb.Message.getFieldWithDefault(msg, 28, ""),
    discovergy: (f = msg.getDiscovergy()) && proto.types.DiscovergyRegistrationDetails.toObject(includeInstance, f),
    bluemetering: (f = msg.getBluemetering()) && proto.types.BlueMeteringRegistrationDetails.toObject(includeInstance, f),
    bsed: (f = msg.getBsed()) && proto.types.BSEDRegistrationDetails.toObject(includeInstance, f),
    smartme: (f = msg.getSmartme()) && proto.types.SmartMeRegistrationDetails.toObject(includeInstance, f),
    easee: (f = msg.getEasee()) && proto.types.EaseeRegistrationDetails.toObject(includeInstance, f),
    shelly: (f = msg.getShelly()) && proto.types.ShellyRegistrationDetails.toObject(includeInstance, f),
    wattline: (f = msg.getWattline()) && proto.types.WattlineRegistrationDetails.toObject(includeInstance, f),
    mscons: (f = msg.getMscons()) && proto.types.MsconsRegistrationDetails.toObject(includeInstance, f),
    nonSmartMeter: (f = msg.getNonSmartMeter()) && proto.types.NonSmartMeterRegistrationDetails.toObject(includeInstance, f),
    goE: (f = msg.getGoE()) && proto.types.GoERegistrationDetails.toObject(includeInstance, f),
    mystrom: (f = msg.getMystrom()) && proto.types.MyStromRegistrationDetails.toObject(includeInstance, f),
    viessmann: (f = msg.getViessmann()) && proto.types.ViessmannRegistrationDetails.toObject(includeInstance, f),
    tsun: (f = msg.getTsun()) && proto.types.TsunRegistrationDetails.toObject(includeInstance, f),
    external: (f = msg.getExternal()) && proto.types.ExternalRegistrationDetails.toObject(includeInstance, f),
    eda: (f = msg.getEda()) && proto.types.EdaRegistrationDetails.toObject(includeInstance, f),
    auxilius: (f = msg.getAuxilius()) && proto.types.AuxiliusRegistrationDetails.toObject(includeInstance, f),
    tibber: (f = msg.getTibber()) && proto.types.TibberRegistrationDetails.toObject(includeInstance, f),
    ocpp: (f = msg.getOcpp()) && proto.types.OcppRegistrationDetails.toObject(includeInstance, f),
    lobaro: (f = msg.getLobaro()) && proto.types.LobaroRegistrationDetails.toObject(includeInstance, f),
    virtualDevice: (f = msg.getVirtualDevice()) && proto.types.VirtualDeviceRegistrationDetails.toObject(includeInstance, f),
    solaredge: (f = msg.getSolaredge()) && proto.types.SolarEdgeRegistrationDetails.toObject(includeInstance, f),
    metiundo: (f = msg.getMetiundo()) && proto.types.MetiundoRegistrationDetails.toObject(includeInstance, f),
    enbw: (f = msg.getEnbw()) && proto.types.EnbwRegistrationDetails.toObject(includeInstance, f),
    bitshake: (f = msg.getBitshake()) && proto.types.BitShakeRegistrationDetails.toObject(includeInstance, f),
    iometer: (f = msg.getIometer()) && proto.types.IoMeterRegistrationDetails.toObject(includeInstance, f),
    meteocontrol: (f = msg.getMeteocontrol()) && proto.types.MeteocontrolRegistrationDetails.toObject(includeInstance, f),
    chargecloud: (f = msg.getChargecloud()) && proto.types.ChargecloudRegistrationDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceRegistration}
 */
proto.types.DeviceRegistration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceRegistration;
  return proto.types.DeviceRegistration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceRegistration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceRegistration}
 */
proto.types.DeviceRegistration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 2:
      var value = new proto.types.DeviceRegistration.SpaceMapping;
      reader.readMessage(value,proto.types.DeviceRegistration.SpaceMapping.deserializeBinaryFromReader);
      msg.setSpaceMapping(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Device.Type} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 4:
      var value = /** @type {!proto.types.Device.Provider} */ (reader.readEnum());
      msg.setDeviceProvider(value);
      break;
    case 5:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setDeviceMedium(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    case 6:
      var value = new proto.types.DiscovergyRegistrationDetails;
      reader.readMessage(value,proto.types.DiscovergyRegistrationDetails.deserializeBinaryFromReader);
      msg.setDiscovergy(value);
      break;
    case 7:
      var value = new proto.types.BlueMeteringRegistrationDetails;
      reader.readMessage(value,proto.types.BlueMeteringRegistrationDetails.deserializeBinaryFromReader);
      msg.setBluemetering(value);
      break;
    case 8:
      var value = new proto.types.BSEDRegistrationDetails;
      reader.readMessage(value,proto.types.BSEDRegistrationDetails.deserializeBinaryFromReader);
      msg.setBsed(value);
      break;
    case 9:
      var value = new proto.types.SmartMeRegistrationDetails;
      reader.readMessage(value,proto.types.SmartMeRegistrationDetails.deserializeBinaryFromReader);
      msg.setSmartme(value);
      break;
    case 10:
      var value = new proto.types.EaseeRegistrationDetails;
      reader.readMessage(value,proto.types.EaseeRegistrationDetails.deserializeBinaryFromReader);
      msg.setEasee(value);
      break;
    case 11:
      var value = new proto.types.ShellyRegistrationDetails;
      reader.readMessage(value,proto.types.ShellyRegistrationDetails.deserializeBinaryFromReader);
      msg.setShelly(value);
      break;
    case 12:
      var value = new proto.types.WattlineRegistrationDetails;
      reader.readMessage(value,proto.types.WattlineRegistrationDetails.deserializeBinaryFromReader);
      msg.setWattline(value);
      break;
    case 13:
      var value = new proto.types.MsconsRegistrationDetails;
      reader.readMessage(value,proto.types.MsconsRegistrationDetails.deserializeBinaryFromReader);
      msg.setMscons(value);
      break;
    case 14:
      var value = new proto.types.NonSmartMeterRegistrationDetails;
      reader.readMessage(value,proto.types.NonSmartMeterRegistrationDetails.deserializeBinaryFromReader);
      msg.setNonSmartMeter(value);
      break;
    case 15:
      var value = new proto.types.GoERegistrationDetails;
      reader.readMessage(value,proto.types.GoERegistrationDetails.deserializeBinaryFromReader);
      msg.setGoE(value);
      break;
    case 16:
      var value = new proto.types.MyStromRegistrationDetails;
      reader.readMessage(value,proto.types.MyStromRegistrationDetails.deserializeBinaryFromReader);
      msg.setMystrom(value);
      break;
    case 17:
      var value = new proto.types.ViessmannRegistrationDetails;
      reader.readMessage(value,proto.types.ViessmannRegistrationDetails.deserializeBinaryFromReader);
      msg.setViessmann(value);
      break;
    case 18:
      var value = new proto.types.TsunRegistrationDetails;
      reader.readMessage(value,proto.types.TsunRegistrationDetails.deserializeBinaryFromReader);
      msg.setTsun(value);
      break;
    case 19:
      var value = new proto.types.ExternalRegistrationDetails;
      reader.readMessage(value,proto.types.ExternalRegistrationDetails.deserializeBinaryFromReader);
      msg.setExternal(value);
      break;
    case 20:
      var value = new proto.types.EdaRegistrationDetails;
      reader.readMessage(value,proto.types.EdaRegistrationDetails.deserializeBinaryFromReader);
      msg.setEda(value);
      break;
    case 21:
      var value = new proto.types.AuxiliusRegistrationDetails;
      reader.readMessage(value,proto.types.AuxiliusRegistrationDetails.deserializeBinaryFromReader);
      msg.setAuxilius(value);
      break;
    case 22:
      var value = new proto.types.TibberRegistrationDetails;
      reader.readMessage(value,proto.types.TibberRegistrationDetails.deserializeBinaryFromReader);
      msg.setTibber(value);
      break;
    case 23:
      var value = new proto.types.OcppRegistrationDetails;
      reader.readMessage(value,proto.types.OcppRegistrationDetails.deserializeBinaryFromReader);
      msg.setOcpp(value);
      break;
    case 24:
      var value = new proto.types.LobaroRegistrationDetails;
      reader.readMessage(value,proto.types.LobaroRegistrationDetails.deserializeBinaryFromReader);
      msg.setLobaro(value);
      break;
    case 25:
      var value = new proto.types.VirtualDeviceRegistrationDetails;
      reader.readMessage(value,proto.types.VirtualDeviceRegistrationDetails.deserializeBinaryFromReader);
      msg.setVirtualDevice(value);
      break;
    case 26:
      var value = new proto.types.SolarEdgeRegistrationDetails;
      reader.readMessage(value,proto.types.SolarEdgeRegistrationDetails.deserializeBinaryFromReader);
      msg.setSolaredge(value);
      break;
    case 27:
      var value = new proto.types.MetiundoRegistrationDetails;
      reader.readMessage(value,proto.types.MetiundoRegistrationDetails.deserializeBinaryFromReader);
      msg.setMetiundo(value);
      break;
    case 29:
      var value = new proto.types.EnbwRegistrationDetails;
      reader.readMessage(value,proto.types.EnbwRegistrationDetails.deserializeBinaryFromReader);
      msg.setEnbw(value);
      break;
    case 30:
      var value = new proto.types.BitShakeRegistrationDetails;
      reader.readMessage(value,proto.types.BitShakeRegistrationDetails.deserializeBinaryFromReader);
      msg.setBitshake(value);
      break;
    case 31:
      var value = new proto.types.IoMeterRegistrationDetails;
      reader.readMessage(value,proto.types.IoMeterRegistrationDetails.deserializeBinaryFromReader);
      msg.setIometer(value);
      break;
    case 32:
      var value = new proto.types.MeteocontrolRegistrationDetails;
      reader.readMessage(value,proto.types.MeteocontrolRegistrationDetails.deserializeBinaryFromReader);
      msg.setMeteocontrol(value);
      break;
    case 33:
      var value = new proto.types.ChargecloudRegistrationDetails;
      reader.readMessage(value,proto.types.ChargecloudRegistrationDetails.deserializeBinaryFromReader);
      msg.setChargecloud(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceRegistration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceRegistration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceRegistration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceRegistration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceMapping();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.types.DeviceRegistration.SpaceMapping.serializeBinaryToWriter
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDeviceProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDeviceMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getDiscovergy();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.types.DiscovergyRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getBluemetering();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.types.BlueMeteringRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getBsed();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.types.BSEDRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getSmartme();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.types.SmartMeRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getEasee();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.types.EaseeRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getShelly();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.types.ShellyRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getWattline();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.types.WattlineRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getMscons();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.types.MsconsRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getNonSmartMeter();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.types.NonSmartMeterRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getGoE();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.types.GoERegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getMystrom();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.types.MyStromRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getViessmann();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.types.ViessmannRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getTsun();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.types.TsunRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getExternal();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.types.ExternalRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getEda();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.types.EdaRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getAuxilius();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.types.AuxiliusRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getTibber();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.types.TibberRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getOcpp();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.types.OcppRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getLobaro();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.types.LobaroRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getVirtualDevice();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.types.VirtualDeviceRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getSolaredge();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.types.SolarEdgeRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getMetiundo();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.types.MetiundoRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getEnbw();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.types.EnbwRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getBitshake();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.types.BitShakeRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getIometer();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.types.IoMeterRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getMeteocontrol();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.types.MeteocontrolRegistrationDetails.serializeBinaryToWriter
    );
  }
  f = message.getChargecloud();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.types.ChargecloudRegistrationDetails.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceRegistration.SpaceMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceRegistration.SpaceMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceRegistration.SpaceMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceRegistration.SpaceMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isMainDevice: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceRegistration.SpaceMapping}
 */
proto.types.DeviceRegistration.SpaceMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceRegistration.SpaceMapping;
  return proto.types.DeviceRegistration.SpaceMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceRegistration.SpaceMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceRegistration.SpaceMapping}
 */
proto.types.DeviceRegistration.SpaceMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMainDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceRegistration.SpaceMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceRegistration.SpaceMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceRegistration.SpaceMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceRegistration.SpaceMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsMainDevice();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.types.DeviceRegistration.SpaceMapping.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceRegistration.SpaceMapping} returns this
 */
proto.types.DeviceRegistration.SpaceMapping.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_main_device = 2;
 * @return {boolean}
 */
proto.types.DeviceRegistration.SpaceMapping.prototype.getIsMainDevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.DeviceRegistration.SpaceMapping} returns this
 */
proto.types.DeviceRegistration.SpaceMapping.prototype.setIsMainDevice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string device_name = 1;
 * @return {string}
 */
proto.types.DeviceRegistration.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SpaceMapping space_mapping = 2;
 * @return {?proto.types.DeviceRegistration.SpaceMapping}
 */
proto.types.DeviceRegistration.prototype.getSpaceMapping = function() {
  return /** @type{?proto.types.DeviceRegistration.SpaceMapping} */ (
    jspb.Message.getWrapperField(this, proto.types.DeviceRegistration.SpaceMapping, 2));
};


/**
 * @param {?proto.types.DeviceRegistration.SpaceMapping|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setSpaceMapping = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearSpaceMapping = function() {
  return this.setSpaceMapping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasSpaceMapping = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Device.Type device_type = 3;
 * @return {!proto.types.Device.Type}
 */
proto.types.DeviceRegistration.prototype.getDeviceType = function() {
  return /** @type {!proto.types.Device.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Device.Type} value
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Device.Provider device_provider = 4;
 * @return {!proto.types.Device.Provider}
 */
proto.types.DeviceRegistration.prototype.getDeviceProvider = function() {
  return /** @type {!proto.types.Device.Provider} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.Device.Provider} value
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.setDeviceProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Device.Medium device_medium = 5;
 * @return {!proto.types.Device.Medium}
 */
proto.types.DeviceRegistration.prototype.getDeviceMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.setDeviceMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string partner_id = 28;
 * @return {string}
 */
proto.types.DeviceRegistration.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.setPartnerId = function(value) {
  return jspb.Message.setField(this, 28, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearPartnerId = function() {
  return jspb.Message.setField(this, 28, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasPartnerId = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional DiscovergyRegistrationDetails discovergy = 6;
 * @return {?proto.types.DiscovergyRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getDiscovergy = function() {
  return /** @type{?proto.types.DiscovergyRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.DiscovergyRegistrationDetails, 6));
};


/**
 * @param {?proto.types.DiscovergyRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setDiscovergy = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearDiscovergy = function() {
  return this.setDiscovergy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasDiscovergy = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BlueMeteringRegistrationDetails bluemetering = 7;
 * @return {?proto.types.BlueMeteringRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getBluemetering = function() {
  return /** @type{?proto.types.BlueMeteringRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.BlueMeteringRegistrationDetails, 7));
};


/**
 * @param {?proto.types.BlueMeteringRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setBluemetering = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearBluemetering = function() {
  return this.setBluemetering(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasBluemetering = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional BSEDRegistrationDetails bsed = 8;
 * @return {?proto.types.BSEDRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getBsed = function() {
  return /** @type{?proto.types.BSEDRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.BSEDRegistrationDetails, 8));
};


/**
 * @param {?proto.types.BSEDRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setBsed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearBsed = function() {
  return this.setBsed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasBsed = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SmartMeRegistrationDetails smartme = 9;
 * @return {?proto.types.SmartMeRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getSmartme = function() {
  return /** @type{?proto.types.SmartMeRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.SmartMeRegistrationDetails, 9));
};


/**
 * @param {?proto.types.SmartMeRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setSmartme = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearSmartme = function() {
  return this.setSmartme(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasSmartme = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional EaseeRegistrationDetails easee = 10;
 * @return {?proto.types.EaseeRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getEasee = function() {
  return /** @type{?proto.types.EaseeRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.EaseeRegistrationDetails, 10));
};


/**
 * @param {?proto.types.EaseeRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setEasee = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearEasee = function() {
  return this.setEasee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasEasee = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ShellyRegistrationDetails shelly = 11;
 * @return {?proto.types.ShellyRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getShelly = function() {
  return /** @type{?proto.types.ShellyRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.ShellyRegistrationDetails, 11));
};


/**
 * @param {?proto.types.ShellyRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setShelly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearShelly = function() {
  return this.setShelly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasShelly = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional WattlineRegistrationDetails wattline = 12;
 * @return {?proto.types.WattlineRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getWattline = function() {
  return /** @type{?proto.types.WattlineRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.WattlineRegistrationDetails, 12));
};


/**
 * @param {?proto.types.WattlineRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setWattline = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearWattline = function() {
  return this.setWattline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasWattline = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional MsconsRegistrationDetails mscons = 13;
 * @return {?proto.types.MsconsRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getMscons = function() {
  return /** @type{?proto.types.MsconsRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.MsconsRegistrationDetails, 13));
};


/**
 * @param {?proto.types.MsconsRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setMscons = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearMscons = function() {
  return this.setMscons(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasMscons = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NonSmartMeterRegistrationDetails non_smart_meter = 14;
 * @return {?proto.types.NonSmartMeterRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getNonSmartMeter = function() {
  return /** @type{?proto.types.NonSmartMeterRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.NonSmartMeterRegistrationDetails, 14));
};


/**
 * @param {?proto.types.NonSmartMeterRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setNonSmartMeter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearNonSmartMeter = function() {
  return this.setNonSmartMeter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasNonSmartMeter = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional GoERegistrationDetails go_e = 15;
 * @return {?proto.types.GoERegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getGoE = function() {
  return /** @type{?proto.types.GoERegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.GoERegistrationDetails, 15));
};


/**
 * @param {?proto.types.GoERegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setGoE = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearGoE = function() {
  return this.setGoE(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasGoE = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional MyStromRegistrationDetails mystrom = 16;
 * @return {?proto.types.MyStromRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getMystrom = function() {
  return /** @type{?proto.types.MyStromRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.MyStromRegistrationDetails, 16));
};


/**
 * @param {?proto.types.MyStromRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setMystrom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearMystrom = function() {
  return this.setMystrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasMystrom = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ViessmannRegistrationDetails viessmann = 17;
 * @return {?proto.types.ViessmannRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getViessmann = function() {
  return /** @type{?proto.types.ViessmannRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.ViessmannRegistrationDetails, 17));
};


/**
 * @param {?proto.types.ViessmannRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setViessmann = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearViessmann = function() {
  return this.setViessmann(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasViessmann = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional TsunRegistrationDetails tsun = 18;
 * @return {?proto.types.TsunRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getTsun = function() {
  return /** @type{?proto.types.TsunRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.TsunRegistrationDetails, 18));
};


/**
 * @param {?proto.types.TsunRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setTsun = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearTsun = function() {
  return this.setTsun(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasTsun = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ExternalRegistrationDetails external = 19;
 * @return {?proto.types.ExternalRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getExternal = function() {
  return /** @type{?proto.types.ExternalRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.ExternalRegistrationDetails, 19));
};


/**
 * @param {?proto.types.ExternalRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setExternal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearExternal = function() {
  return this.setExternal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasExternal = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional EdaRegistrationDetails eda = 20;
 * @return {?proto.types.EdaRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getEda = function() {
  return /** @type{?proto.types.EdaRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.EdaRegistrationDetails, 20));
};


/**
 * @param {?proto.types.EdaRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setEda = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearEda = function() {
  return this.setEda(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasEda = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional AuxiliusRegistrationDetails auxilius = 21;
 * @return {?proto.types.AuxiliusRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getAuxilius = function() {
  return /** @type{?proto.types.AuxiliusRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.AuxiliusRegistrationDetails, 21));
};


/**
 * @param {?proto.types.AuxiliusRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setAuxilius = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearAuxilius = function() {
  return this.setAuxilius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasAuxilius = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional TibberRegistrationDetails tibber = 22;
 * @return {?proto.types.TibberRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getTibber = function() {
  return /** @type{?proto.types.TibberRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.TibberRegistrationDetails, 22));
};


/**
 * @param {?proto.types.TibberRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setTibber = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearTibber = function() {
  return this.setTibber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasTibber = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional OcppRegistrationDetails ocpp = 23;
 * @return {?proto.types.OcppRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getOcpp = function() {
  return /** @type{?proto.types.OcppRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.OcppRegistrationDetails, 23));
};


/**
 * @param {?proto.types.OcppRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setOcpp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearOcpp = function() {
  return this.setOcpp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasOcpp = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional LobaroRegistrationDetails lobaro = 24;
 * @return {?proto.types.LobaroRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getLobaro = function() {
  return /** @type{?proto.types.LobaroRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.LobaroRegistrationDetails, 24));
};


/**
 * @param {?proto.types.LobaroRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setLobaro = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearLobaro = function() {
  return this.setLobaro(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasLobaro = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional VirtualDeviceRegistrationDetails virtual_device = 25;
 * @return {?proto.types.VirtualDeviceRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getVirtualDevice = function() {
  return /** @type{?proto.types.VirtualDeviceRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.VirtualDeviceRegistrationDetails, 25));
};


/**
 * @param {?proto.types.VirtualDeviceRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setVirtualDevice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearVirtualDevice = function() {
  return this.setVirtualDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasVirtualDevice = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional SolarEdgeRegistrationDetails solaredge = 26;
 * @return {?proto.types.SolarEdgeRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getSolaredge = function() {
  return /** @type{?proto.types.SolarEdgeRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.SolarEdgeRegistrationDetails, 26));
};


/**
 * @param {?proto.types.SolarEdgeRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setSolaredge = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearSolaredge = function() {
  return this.setSolaredge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasSolaredge = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional MetiundoRegistrationDetails metiundo = 27;
 * @return {?proto.types.MetiundoRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getMetiundo = function() {
  return /** @type{?proto.types.MetiundoRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.MetiundoRegistrationDetails, 27));
};


/**
 * @param {?proto.types.MetiundoRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setMetiundo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearMetiundo = function() {
  return this.setMetiundo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasMetiundo = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional EnbwRegistrationDetails enbw = 29;
 * @return {?proto.types.EnbwRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getEnbw = function() {
  return /** @type{?proto.types.EnbwRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.EnbwRegistrationDetails, 29));
};


/**
 * @param {?proto.types.EnbwRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setEnbw = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearEnbw = function() {
  return this.setEnbw(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasEnbw = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional BitShakeRegistrationDetails bitshake = 30;
 * @return {?proto.types.BitShakeRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getBitshake = function() {
  return /** @type{?proto.types.BitShakeRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.BitShakeRegistrationDetails, 30));
};


/**
 * @param {?proto.types.BitShakeRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setBitshake = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearBitshake = function() {
  return this.setBitshake(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasBitshake = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional IoMeterRegistrationDetails iometer = 31;
 * @return {?proto.types.IoMeterRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getIometer = function() {
  return /** @type{?proto.types.IoMeterRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.IoMeterRegistrationDetails, 31));
};


/**
 * @param {?proto.types.IoMeterRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setIometer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearIometer = function() {
  return this.setIometer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasIometer = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional MeteocontrolRegistrationDetails meteocontrol = 32;
 * @return {?proto.types.MeteocontrolRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getMeteocontrol = function() {
  return /** @type{?proto.types.MeteocontrolRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.MeteocontrolRegistrationDetails, 32));
};


/**
 * @param {?proto.types.MeteocontrolRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setMeteocontrol = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearMeteocontrol = function() {
  return this.setMeteocontrol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasMeteocontrol = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional ChargecloudRegistrationDetails chargecloud = 33;
 * @return {?proto.types.ChargecloudRegistrationDetails}
 */
proto.types.DeviceRegistration.prototype.getChargecloud = function() {
  return /** @type{?proto.types.ChargecloudRegistrationDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.ChargecloudRegistrationDetails, 33));
};


/**
 * @param {?proto.types.ChargecloudRegistrationDetails|undefined} value
 * @return {!proto.types.DeviceRegistration} returns this
*/
proto.types.DeviceRegistration.prototype.setChargecloud = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.types.DeviceRegistration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DeviceRegistration} returns this
 */
proto.types.DeviceRegistration.prototype.clearChargecloud = function() {
  return this.setChargecloud(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceRegistration.prototype.hasChargecloud = function() {
  return jspb.Message.getField(this, 33) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DiscovergyRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DiscovergyRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DiscovergyRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DiscovergyRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    fullSerialNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DiscovergyRegistrationDetails}
 */
proto.types.DiscovergyRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DiscovergyRegistrationDetails;
  return proto.types.DiscovergyRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DiscovergyRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DiscovergyRegistrationDetails}
 */
proto.types.DiscovergyRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DiscovergyRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DiscovergyRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DiscovergyRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DiscovergyRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFullSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string full_serial_number = 1;
 * @return {string}
 */
proto.types.DiscovergyRegistrationDetails.prototype.getFullSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DiscovergyRegistrationDetails} returns this
 */
proto.types.DiscovergyRegistrationDetails.prototype.setFullSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.BlueMeteringRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.BlueMeteringRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.BlueMeteringRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.BlueMeteringRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    meteringLocationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bluemeteringApiKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    meterPin: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.BlueMeteringRegistrationDetails}
 */
proto.types.BlueMeteringRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.BlueMeteringRegistrationDetails;
  return proto.types.BlueMeteringRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.BlueMeteringRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.BlueMeteringRegistrationDetails}
 */
proto.types.BlueMeteringRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeteringLocationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBluemeteringApiKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.BlueMeteringRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.BlueMeteringRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.BlueMeteringRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.BlueMeteringRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeteringLocationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBluemeteringApiKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string metering_location_id = 1;
 * @return {string}
 */
proto.types.BlueMeteringRegistrationDetails.prototype.getMeteringLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.BlueMeteringRegistrationDetails} returns this
 */
proto.types.BlueMeteringRegistrationDetails.prototype.setMeteringLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bluemetering_api_key = 2;
 * @return {string}
 */
proto.types.BlueMeteringRegistrationDetails.prototype.getBluemeteringApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.BlueMeteringRegistrationDetails} returns this
 */
proto.types.BlueMeteringRegistrationDetails.prototype.setBluemeteringApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string meter_pin = 3;
 * @return {string}
 */
proto.types.BlueMeteringRegistrationDetails.prototype.getMeterPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.BlueMeteringRegistrationDetails} returns this
 */
proto.types.BlueMeteringRegistrationDetails.prototype.setMeterPin = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.BlueMeteringRegistrationDetails} returns this
 */
proto.types.BlueMeteringRegistrationDetails.prototype.clearMeterPin = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.BlueMeteringRegistrationDetails.prototype.hasMeterPin = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.BSEDRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.BSEDRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.BSEDRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.BSEDRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.BSEDRegistrationDetails}
 */
proto.types.BSEDRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.BSEDRegistrationDetails;
  return proto.types.BSEDRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.BSEDRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.BSEDRegistrationDetails}
 */
proto.types.BSEDRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.BSEDRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.BSEDRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.BSEDRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.BSEDRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.types.BSEDRegistrationDetails.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.BSEDRegistrationDetails} returns this
 */
proto.types.BSEDRegistrationDetails.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SmartMeRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SmartMeRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SmartMeRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SmartMeRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    smartMeDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    smartMeDeviceSerial: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SmartMeRegistrationDetails}
 */
proto.types.SmartMeRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SmartMeRegistrationDetails;
  return proto.types.SmartMeRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SmartMeRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SmartMeRegistrationDetails}
 */
proto.types.SmartMeRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmartMeDeviceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSmartMeDeviceSerial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SmartMeRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SmartMeRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SmartMeRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SmartMeRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartMeDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSmartMeDeviceSerial();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string smart_me_device_id = 1;
 * @return {string}
 */
proto.types.SmartMeRegistrationDetails.prototype.getSmartMeDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SmartMeRegistrationDetails} returns this
 */
proto.types.SmartMeRegistrationDetails.prototype.setSmartMeDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 smart_me_device_serial = 2;
 * @return {number}
 */
proto.types.SmartMeRegistrationDetails.prototype.getSmartMeDeviceSerial = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.SmartMeRegistrationDetails} returns this
 */
proto.types.SmartMeRegistrationDetails.prototype.setSmartMeDeviceSerial = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.EaseeRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.EaseeRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.EaseeRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EaseeRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    easeeSerialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    easeePinCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.EaseeRegistrationDetails}
 */
proto.types.EaseeRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.EaseeRegistrationDetails;
  return proto.types.EaseeRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.EaseeRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.EaseeRegistrationDetails}
 */
proto.types.EaseeRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEaseeSerialNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEaseePinCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.EaseeRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.EaseeRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.EaseeRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EaseeRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEaseeSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEaseePinCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string easee_serial_number = 1;
 * @return {string}
 */
proto.types.EaseeRegistrationDetails.prototype.getEaseeSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EaseeRegistrationDetails} returns this
 */
proto.types.EaseeRegistrationDetails.prototype.setEaseeSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string easee_pin_code = 2;
 * @return {string}
 */
proto.types.EaseeRegistrationDetails.prototype.getEaseePinCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EaseeRegistrationDetails} returns this
 */
proto.types.EaseeRegistrationDetails.prototype.setEaseePinCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.ShellyRegistrationDetails.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ShellyRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ShellyRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ShellyRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ShellyRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    shellyDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelDirectionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ShellyRegistrationDetails}
 */
proto.types.ShellyRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ShellyRegistrationDetails;
  return proto.types.ShellyRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ShellyRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ShellyRegistrationDetails}
 */
proto.types.ShellyRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShellyDeviceId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.types.Direction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannelDirections(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ShellyRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ShellyRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ShellyRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ShellyRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShellyDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelDirectionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string shelly_device_id = 1;
 * @return {string}
 */
proto.types.ShellyRegistrationDetails.prototype.getShellyDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ShellyRegistrationDetails} returns this
 */
proto.types.ShellyRegistrationDetails.prototype.setShellyDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Direction channel_directions = 2;
 * @return {!Array<!proto.types.Direction>}
 */
proto.types.ShellyRegistrationDetails.prototype.getChannelDirectionsList = function() {
  return /** @type {!Array<!proto.types.Direction>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.types.Direction>} value
 * @return {!proto.types.ShellyRegistrationDetails} returns this
 */
proto.types.ShellyRegistrationDetails.prototype.setChannelDirectionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.types.Direction} value
 * @param {number=} opt_index
 * @return {!proto.types.ShellyRegistrationDetails} returns this
 */
proto.types.ShellyRegistrationDetails.prototype.addChannelDirections = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.ShellyRegistrationDetails} returns this
 */
proto.types.ShellyRegistrationDetails.prototype.clearChannelDirectionsList = function() {
  return this.setChannelDirectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.WattlineRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.WattlineRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.WattlineRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WattlineRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    meloId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.WattlineRegistrationDetails}
 */
proto.types.WattlineRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.WattlineRegistrationDetails;
  return proto.types.WattlineRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.WattlineRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.WattlineRegistrationDetails}
 */
proto.types.WattlineRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeloId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.WattlineRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.WattlineRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.WattlineRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WattlineRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeloId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string melo_id = 1;
 * @return {string}
 */
proto.types.WattlineRegistrationDetails.prototype.getMeloId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.WattlineRegistrationDetails} returns this
 */
proto.types.WattlineRegistrationDetails.prototype.setMeloId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MsconsRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MsconsRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MsconsRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MsconsRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locationIdType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MsconsRegistrationDetails}
 */
proto.types.MsconsRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MsconsRegistrationDetails;
  return proto.types.MsconsRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MsconsRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MsconsRegistrationDetails}
 */
proto.types.MsconsRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.MsconsRegistrationDetails.LocationIdType} */ (reader.readEnum());
      msg.setLocationIdType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MsconsRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MsconsRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MsconsRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MsconsRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocationIdType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.MsconsRegistrationDetails.LocationIdType = {
  MARKET: 0,
  MEASUREMENT: 1
};

/**
 * optional string location_id = 1;
 * @return {string}
 */
proto.types.MsconsRegistrationDetails.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MsconsRegistrationDetails} returns this
 */
proto.types.MsconsRegistrationDetails.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LocationIdType location_id_type = 2;
 * @return {!proto.types.MsconsRegistrationDetails.LocationIdType}
 */
proto.types.MsconsRegistrationDetails.prototype.getLocationIdType = function() {
  return /** @type {!proto.types.MsconsRegistrationDetails.LocationIdType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.MsconsRegistrationDetails.LocationIdType} value
 * @return {!proto.types.MsconsRegistrationDetails} returns this
 */
proto.types.MsconsRegistrationDetails.prototype.setLocationIdType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MyStromRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MyStromRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MyStromRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MyStromRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    myStromDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MyStromRegistrationDetails}
 */
proto.types.MyStromRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MyStromRegistrationDetails;
  return proto.types.MyStromRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MyStromRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MyStromRegistrationDetails}
 */
proto.types.MyStromRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMyStromDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MyStromRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MyStromRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MyStromRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MyStromRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMyStromDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.types.Direction} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string my_strom_device_id = 1;
 * @return {string}
 */
proto.types.MyStromRegistrationDetails.prototype.getMyStromDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MyStromRegistrationDetails} returns this
 */
proto.types.MyStromRegistrationDetails.prototype.setMyStromDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.types.MyStromRegistrationDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MyStromRegistrationDetails} returns this
 */
proto.types.MyStromRegistrationDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.types.MyStromRegistrationDetails.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MyStromRegistrationDetails} returns this
 */
proto.types.MyStromRegistrationDetails.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Direction direction = 4;
 * @return {!proto.types.Direction}
 */
proto.types.MyStromRegistrationDetails.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.types.MyStromRegistrationDetails} returns this
 */
proto.types.MyStromRegistrationDetails.prototype.setDirection = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.MyStromRegistrationDetails} returns this
 */
proto.types.MyStromRegistrationDetails.prototype.clearDirection = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MyStromRegistrationDetails.prototype.hasDirection = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TsunRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TsunRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TsunRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TsunRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    tsunDeviceGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TsunRegistrationDetails}
 */
proto.types.TsunRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TsunRegistrationDetails;
  return proto.types.TsunRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TsunRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TsunRegistrationDetails}
 */
proto.types.TsunRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTsunDeviceGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TsunRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TsunRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TsunRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TsunRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTsunDeviceGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tsun_device_guid = 1;
 * @return {string}
 */
proto.types.TsunRegistrationDetails.prototype.getTsunDeviceGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.TsunRegistrationDetails} returns this
 */
proto.types.TsunRegistrationDetails.prototype.setTsunDeviceGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.types.TsunRegistrationDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.TsunRegistrationDetails} returns this
 */
proto.types.TsunRegistrationDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.types.TsunRegistrationDetails.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.TsunRegistrationDetails} returns this
 */
proto.types.TsunRegistrationDetails.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.GoERegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.GoERegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.GoERegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.GoERegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apiVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.GoERegistrationDetails}
 */
proto.types.GoERegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.GoERegistrationDetails;
  return proto.types.GoERegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.GoERegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.GoERegistrationDetails}
 */
proto.types.GoERegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.types.GoERegistrationDetails.APIVersion} */ (reader.readEnum());
      msg.setApiVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.GoERegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.GoERegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.GoERegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.GoERegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApiVersion();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.GoERegistrationDetails.APIVersion = {
  V1: 0,
  V2: 1
};

/**
 * optional string api_token = 1;
 * @return {string}
 */
proto.types.GoERegistrationDetails.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.GoERegistrationDetails} returns this
 */
proto.types.GoERegistrationDetails.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serial_number = 2;
 * @return {string}
 */
proto.types.GoERegistrationDetails.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.GoERegistrationDetails} returns this
 */
proto.types.GoERegistrationDetails.prototype.setSerialNumber = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.GoERegistrationDetails} returns this
 */
proto.types.GoERegistrationDetails.prototype.clearSerialNumber = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.GoERegistrationDetails.prototype.hasSerialNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional APIVersion api_version = 3;
 * @return {!proto.types.GoERegistrationDetails.APIVersion}
 */
proto.types.GoERegistrationDetails.prototype.getApiVersion = function() {
  return /** @type {!proto.types.GoERegistrationDetails.APIVersion} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.GoERegistrationDetails.APIVersion} value
 * @return {!proto.types.GoERegistrationDetails} returns this
 */
proto.types.GoERegistrationDetails.prototype.setApiVersion = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.NonSmartMeterRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.NonSmartMeterRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.NonSmartMeterRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    isBidirectional: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    manualReading: (f = msg.getManualReading()) && types_device_reading_pb.ManualReading.toObject(includeInstance, f),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.NonSmartMeterRegistrationDetails}
 */
proto.types.NonSmartMeterRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.NonSmartMeterRegistrationDetails;
  return proto.types.NonSmartMeterRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.NonSmartMeterRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.NonSmartMeterRegistrationDetails}
 */
proto.types.NonSmartMeterRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBidirectional(value);
      break;
    case 2:
      var value = new types_device_reading_pb.ManualReading;
      reader.readMessage(value,types_device_reading_pb.ManualReading.deserializeBinaryFromReader);
      msg.setManualReading(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.NonSmartMeterRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.NonSmartMeterRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.NonSmartMeterRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsBidirectional();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getManualReading();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_device_reading_pb.ManualReading.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_bidirectional = 1;
 * @return {boolean}
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.getIsBidirectional = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.NonSmartMeterRegistrationDetails} returns this
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.setIsBidirectional = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional ManualReading manual_reading = 2;
 * @return {?proto.types.ManualReading}
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.getManualReading = function() {
  return /** @type{?proto.types.ManualReading} */ (
    jspb.Message.getWrapperField(this, types_device_reading_pb.ManualReading, 2));
};


/**
 * @param {?proto.types.ManualReading|undefined} value
 * @return {!proto.types.NonSmartMeterRegistrationDetails} returns this
*/
proto.types.NonSmartMeterRegistrationDetails.prototype.setManualReading = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.NonSmartMeterRegistrationDetails} returns this
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.clearManualReading = function() {
  return this.setManualReading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.hasManualReading = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string serial_number = 3;
 * @return {string}
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.NonSmartMeterRegistrationDetails} returns this
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.setSerialNumber = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.NonSmartMeterRegistrationDetails} returns this
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.clearSerialNumber = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.NonSmartMeterRegistrationDetails.prototype.hasSerialNumber = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ViessmannRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ViessmannRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ViessmannRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ViessmannRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    viessmannDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gatewaySerial: jspb.Message.getFieldWithDefault(msg, 2, ""),
    installationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    boilerSerial: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ViessmannRegistrationDetails}
 */
proto.types.ViessmannRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ViessmannRegistrationDetails;
  return proto.types.ViessmannRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ViessmannRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ViessmannRegistrationDetails}
 */
proto.types.ViessmannRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setViessmannDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewaySerial(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBoilerSerial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ViessmannRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ViessmannRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ViessmannRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ViessmannRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViessmannDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGatewaySerial();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstallationId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBoilerSerial();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string viessmann_device_id = 1;
 * @return {string}
 */
proto.types.ViessmannRegistrationDetails.prototype.getViessmannDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ViessmannRegistrationDetails} returns this
 */
proto.types.ViessmannRegistrationDetails.prototype.setViessmannDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string gateway_serial = 2;
 * @return {string}
 */
proto.types.ViessmannRegistrationDetails.prototype.getGatewaySerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ViessmannRegistrationDetails} returns this
 */
proto.types.ViessmannRegistrationDetails.prototype.setGatewaySerial = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 installation_id = 3;
 * @return {number}
 */
proto.types.ViessmannRegistrationDetails.prototype.getInstallationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.ViessmannRegistrationDetails} returns this
 */
proto.types.ViessmannRegistrationDetails.prototype.setInstallationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string boiler_serial = 4;
 * @return {string}
 */
proto.types.ViessmannRegistrationDetails.prototype.getBoilerSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ViessmannRegistrationDetails} returns this
 */
proto.types.ViessmannRegistrationDetails.prototype.setBoilerSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ExternalRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ExternalRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ExternalRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ExternalRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meteringLocationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ExternalRegistrationDetails}
 */
proto.types.ExternalRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ExternalRegistrationDetails;
  return proto.types.ExternalRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ExternalRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ExternalRegistrationDetails}
 */
proto.types.ExternalRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeteringLocationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ExternalRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ExternalRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ExternalRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ExternalRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string external_device_id = 1;
 * @return {string}
 */
proto.types.ExternalRegistrationDetails.prototype.getExternalDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ExternalRegistrationDetails} returns this
 */
proto.types.ExternalRegistrationDetails.prototype.setExternalDeviceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.ExternalRegistrationDetails} returns this
 */
proto.types.ExternalRegistrationDetails.prototype.clearExternalDeviceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ExternalRegistrationDetails.prototype.hasExternalDeviceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string metering_location_id = 2;
 * @return {string}
 */
proto.types.ExternalRegistrationDetails.prototype.getMeteringLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ExternalRegistrationDetails} returns this
 */
proto.types.ExternalRegistrationDetails.prototype.setMeteringLocationId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.ExternalRegistrationDetails} returns this
 */
proto.types.ExternalRegistrationDetails.prototype.clearMeteringLocationId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ExternalRegistrationDetails.prototype.hasMeteringLocationId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string serial_number = 3;
 * @return {string}
 */
proto.types.ExternalRegistrationDetails.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ExternalRegistrationDetails} returns this
 */
proto.types.ExternalRegistrationDetails.prototype.setSerialNumber = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.ExternalRegistrationDetails} returns this
 */
proto.types.ExternalRegistrationDetails.prototype.clearSerialNumber = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ExternalRegistrationDetails.prototype.hasSerialNumber = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.EdaRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.EdaRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.EdaRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EdaRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    meteringPoint: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.EdaRegistrationDetails}
 */
proto.types.EdaRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.EdaRegistrationDetails;
  return proto.types.EdaRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.EdaRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.EdaRegistrationDetails}
 */
proto.types.EdaRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeteringPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.EdaRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.EdaRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.EdaRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EdaRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeteringPoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string metering_point = 1;
 * @return {string}
 */
proto.types.EdaRegistrationDetails.prototype.getMeteringPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EdaRegistrationDetails} returns this
 */
proto.types.EdaRegistrationDetails.prototype.setMeteringPoint = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.AuxiliusRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.AuxiliusRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.AuxiliusRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AuxiliusRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    meteringCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.AuxiliusRegistrationDetails}
 */
proto.types.AuxiliusRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.AuxiliusRegistrationDetails;
  return proto.types.AuxiliusRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.AuxiliusRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.AuxiliusRegistrationDetails}
 */
proto.types.AuxiliusRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeteringCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.AuxiliusRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.AuxiliusRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.AuxiliusRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AuxiliusRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeteringCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string metering_code = 1;
 * @return {string}
 */
proto.types.AuxiliusRegistrationDetails.prototype.getMeteringCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AuxiliusRegistrationDetails} returns this
 */
proto.types.AuxiliusRegistrationDetails.prototype.setMeteringCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TibberRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TibberRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TibberRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TibberRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    homeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TibberRegistrationDetails}
 */
proto.types.TibberRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TibberRegistrationDetails;
  return proto.types.TibberRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TibberRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TibberRegistrationDetails}
 */
proto.types.TibberRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TibberRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TibberRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TibberRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TibberRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHomeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string home_id = 1;
 * @return {string}
 */
proto.types.TibberRegistrationDetails.prototype.getHomeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.TibberRegistrationDetails} returns this
 */
proto.types.TibberRegistrationDetails.prototype.setHomeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auth_token = 2;
 * @return {string}
 */
proto.types.TibberRegistrationDetails.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.TibberRegistrationDetails} returns this
 */
proto.types.TibberRegistrationDetails.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.LobaroRegistrationDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LobaroRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LobaroRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LobaroRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LobaroRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    lobaroDevicesList: jspb.Message.toObjectList(msg.getLobaroDevicesList(),
    proto.types.LobaroRegistrationDetails.DeviceDetails.toObject, includeInstance),
    authToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LobaroRegistrationDetails}
 */
proto.types.LobaroRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LobaroRegistrationDetails;
  return proto.types.LobaroRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LobaroRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LobaroRegistrationDetails}
 */
proto.types.LobaroRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.LobaroRegistrationDetails.DeviceDetails;
      reader.readMessage(value,proto.types.LobaroRegistrationDetails.DeviceDetails.deserializeBinaryFromReader);
      msg.addLobaroDevices(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LobaroRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LobaroRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LobaroRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LobaroRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLobaroDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.types.LobaroRegistrationDetails.DeviceDetails.serializeBinaryToWriter
    );
  }
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LobaroRegistrationDetails.DeviceDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LobaroRegistrationDetails.DeviceDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    idDataLinkLayer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LobaroRegistrationDetails.DeviceDetails}
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LobaroRegistrationDetails.DeviceDetails;
  return proto.types.LobaroRegistrationDetails.DeviceDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LobaroRegistrationDetails.DeviceDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LobaroRegistrationDetails.DeviceDetails}
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdDataLinkLayer(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGatewayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LobaroRegistrationDetails.DeviceDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LobaroRegistrationDetails.DeviceDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdDataLinkLayer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id_data_link_layer = 1;
 * @return {string}
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.prototype.getIdDataLinkLayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LobaroRegistrationDetails.DeviceDetails} returns this
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.prototype.setIdDataLinkLayer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 gateway_id = 2;
 * @return {number}
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.prototype.getGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.LobaroRegistrationDetails.DeviceDetails} returns this
 */
proto.types.LobaroRegistrationDetails.DeviceDetails.prototype.setGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated DeviceDetails lobaro_devices = 1;
 * @return {!Array<!proto.types.LobaroRegistrationDetails.DeviceDetails>}
 */
proto.types.LobaroRegistrationDetails.prototype.getLobaroDevicesList = function() {
  return /** @type{!Array<!proto.types.LobaroRegistrationDetails.DeviceDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.LobaroRegistrationDetails.DeviceDetails, 1));
};


/**
 * @param {!Array<!proto.types.LobaroRegistrationDetails.DeviceDetails>} value
 * @return {!proto.types.LobaroRegistrationDetails} returns this
*/
proto.types.LobaroRegistrationDetails.prototype.setLobaroDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.LobaroRegistrationDetails.DeviceDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.LobaroRegistrationDetails.DeviceDetails}
 */
proto.types.LobaroRegistrationDetails.prototype.addLobaroDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.LobaroRegistrationDetails.DeviceDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.LobaroRegistrationDetails} returns this
 */
proto.types.LobaroRegistrationDetails.prototype.clearLobaroDevicesList = function() {
  return this.setLobaroDevicesList([]);
};


/**
 * optional string auth_token = 2;
 * @return {string}
 */
proto.types.LobaroRegistrationDetails.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LobaroRegistrationDetails} returns this
 */
proto.types.LobaroRegistrationDetails.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.OcppRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.OcppRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.OcppRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.OcppRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.OcppRegistrationDetails}
 */
proto.types.OcppRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.OcppRegistrationDetails;
  return proto.types.OcppRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.OcppRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.OcppRegistrationDetails}
 */
proto.types.OcppRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.OcppRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.OcppRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.OcppRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.OcppRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string provider_name = 1;
 * @return {string}
 */
proto.types.OcppRegistrationDetails.prototype.getProviderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.OcppRegistrationDetails} returns this
 */
proto.types.OcppRegistrationDetails.prototype.setProviderName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.VirtualDeviceRegistrationDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.VirtualDeviceRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.VirtualDeviceRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.VirtualDeviceRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.VirtualDeviceRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.VirtualDeviceRegistrationDetails}
 */
proto.types.VirtualDeviceRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.VirtualDeviceRegistrationDetails;
  return proto.types.VirtualDeviceRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.VirtualDeviceRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.VirtualDeviceRegistrationDetails}
 */
proto.types.VirtualDeviceRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.VirtualDeviceRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.VirtualDeviceRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.VirtualDeviceRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.VirtualDeviceRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string device_ids = 1;
 * @return {!Array<string>}
 */
proto.types.VirtualDeviceRegistrationDetails.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.VirtualDeviceRegistrationDetails} returns this
 */
proto.types.VirtualDeviceRegistrationDetails.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.VirtualDeviceRegistrationDetails} returns this
 */
proto.types.VirtualDeviceRegistrationDetails.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.VirtualDeviceRegistrationDetails} returns this
 */
proto.types.VirtualDeviceRegistrationDetails.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.SolarEdgeRegistrationDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SolarEdgeRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SolarEdgeRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SolarEdgeRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SolarEdgeRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.types.SolarEdgeRegistrationDetails.DeviceData.toObject, includeInstance),
    apiKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SolarEdgeRegistrationDetails}
 */
proto.types.SolarEdgeRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SolarEdgeRegistrationDetails;
  return proto.types.SolarEdgeRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SolarEdgeRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SolarEdgeRegistrationDetails}
 */
proto.types.SolarEdgeRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.SolarEdgeRegistrationDetails.DeviceData;
      reader.readMessage(value,proto.types.SolarEdgeRegistrationDetails.DeviceData.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SolarEdgeRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SolarEdgeRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SolarEdgeRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SolarEdgeRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.types.SolarEdgeRegistrationDetails.DeviceData.serializeBinaryToWriter
    );
  }
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SolarEdgeRegistrationDetails.DeviceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SolarEdgeRegistrationDetails.DeviceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SolarEdgeRegistrationDetails.DeviceData}
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SolarEdgeRegistrationDetails.DeviceData;
  return proto.types.SolarEdgeRegistrationDetails.DeviceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SolarEdgeRegistrationDetails.DeviceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SolarEdgeRegistrationDetails.DeviceData}
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SolarEdgeRegistrationDetails.DeviceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SolarEdgeRegistrationDetails.DeviceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 site_id = 1;
 * @return {number}
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.SolarEdgeRegistrationDetails.DeviceData} returns this
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string serial_number = 2;
 * @return {string}
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SolarEdgeRegistrationDetails.DeviceData} returns this
 */
proto.types.SolarEdgeRegistrationDetails.DeviceData.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DeviceData devices = 1;
 * @return {!Array<!proto.types.SolarEdgeRegistrationDetails.DeviceData>}
 */
proto.types.SolarEdgeRegistrationDetails.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.types.SolarEdgeRegistrationDetails.DeviceData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.SolarEdgeRegistrationDetails.DeviceData, 1));
};


/**
 * @param {!Array<!proto.types.SolarEdgeRegistrationDetails.DeviceData>} value
 * @return {!proto.types.SolarEdgeRegistrationDetails} returns this
*/
proto.types.SolarEdgeRegistrationDetails.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.SolarEdgeRegistrationDetails.DeviceData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.SolarEdgeRegistrationDetails.DeviceData}
 */
proto.types.SolarEdgeRegistrationDetails.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.SolarEdgeRegistrationDetails.DeviceData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.SolarEdgeRegistrationDetails} returns this
 */
proto.types.SolarEdgeRegistrationDetails.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional string api_key = 2;
 * @return {string}
 */
proto.types.SolarEdgeRegistrationDetails.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SolarEdgeRegistrationDetails} returns this
 */
proto.types.SolarEdgeRegistrationDetails.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.MetiundoRegistrationDetails.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MetiundoRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MetiundoRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MetiundoRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MetiundoRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginDetails: (f = msg.getLoginDetails()) && proto.types.MetiundoLoginDetails.toObject(includeInstance, f),
    projectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && types_space_pb.Address.toObject(includeInstance, f),
    pvMeteringPointId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    buildingMeteringPointId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tenantMeteringPointIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    userId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MetiundoRegistrationDetails}
 */
proto.types.MetiundoRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MetiundoRegistrationDetails;
  return proto.types.MetiundoRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MetiundoRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MetiundoRegistrationDetails}
 */
proto.types.MetiundoRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.MetiundoLoginDetails;
      reader.readMessage(value,proto.types.MetiundoLoginDetails.deserializeBinaryFromReader);
      msg.setLoginDetails(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 3:
      var value = new types_space_pb.Address;
      reader.readMessage(value,types_space_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPvMeteringPointId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingMeteringPointId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTenantMeteringPointIds(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MetiundoRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MetiundoRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MetiundoRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MetiundoRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.types.MetiundoLoginDetails.serializeBinaryToWriter
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_space_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getPvMeteringPointId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuildingMeteringPointId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTenantMeteringPointIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional MetiundoLoginDetails login_details = 1;
 * @return {?proto.types.MetiundoLoginDetails}
 */
proto.types.MetiundoRegistrationDetails.prototype.getLoginDetails = function() {
  return /** @type{?proto.types.MetiundoLoginDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.MetiundoLoginDetails, 1));
};


/**
 * @param {?proto.types.MetiundoLoginDetails|undefined} value
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
*/
proto.types.MetiundoRegistrationDetails.prototype.setLoginDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.clearLoginDetails = function() {
  return this.setLoginDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MetiundoRegistrationDetails.prototype.hasLoginDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string project_name = 2;
 * @return {string}
 */
proto.types.MetiundoRegistrationDetails.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Address address = 3;
 * @return {?proto.types.Address}
 */
proto.types.MetiundoRegistrationDetails.prototype.getAddress = function() {
  return /** @type{?proto.types.Address} */ (
    jspb.Message.getWrapperField(this, types_space_pb.Address, 3));
};


/**
 * @param {?proto.types.Address|undefined} value
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
*/
proto.types.MetiundoRegistrationDetails.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MetiundoRegistrationDetails.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string pv_metering_point_id = 4;
 * @return {string}
 */
proto.types.MetiundoRegistrationDetails.prototype.getPvMeteringPointId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.setPvMeteringPointId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string building_metering_point_id = 5;
 * @return {string}
 */
proto.types.MetiundoRegistrationDetails.prototype.getBuildingMeteringPointId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.setBuildingMeteringPointId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string tenant_metering_point_ids = 6;
 * @return {!Array<string>}
 */
proto.types.MetiundoRegistrationDetails.prototype.getTenantMeteringPointIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.setTenantMeteringPointIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.addTenantMeteringPointIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.clearTenantMeteringPointIdsList = function() {
  return this.setTenantMeteringPointIdsList([]);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.types.MetiundoRegistrationDetails.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoRegistrationDetails} returns this
 */
proto.types.MetiundoRegistrationDetails.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MetiundoLoginDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MetiundoLoginDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MetiundoLoginDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MetiundoLoginDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MetiundoLoginDetails}
 */
proto.types.MetiundoLoginDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MetiundoLoginDetails;
  return proto.types.MetiundoLoginDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MetiundoLoginDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MetiundoLoginDetails}
 */
proto.types.MetiundoLoginDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MetiundoLoginDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MetiundoLoginDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MetiundoLoginDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MetiundoLoginDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.types.MetiundoLoginDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoLoginDetails} returns this
 */
proto.types.MetiundoLoginDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.types.MetiundoLoginDetails.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoLoginDetails} returns this
 */
proto.types.MetiundoLoginDetails.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.MetiundoMeteringPoint.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MetiundoMeteringPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MetiundoMeteringPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MetiundoMeteringPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MetiundoMeteringPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    melo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sensorIdentifier: jspb.Message.getFieldWithDefault(msg, 6, ""),
    alreadyRegistered: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    firstReadingTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    availableDirectionsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MetiundoMeteringPoint}
 */
proto.types.MetiundoMeteringPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MetiundoMeteringPoint;
  return proto.types.MetiundoMeteringPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MetiundoMeteringPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MetiundoMeteringPoint}
 */
proto.types.MetiundoMeteringPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMelo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorIdentifier(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlreadyRegistered(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstReadingTime(value);
      break;
    case 9:
      var values = /** @type {!Array<!proto.types.Direction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAvailableDirections(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MetiundoMeteringPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MetiundoMeteringPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MetiundoMeteringPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MetiundoMeteringPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMelo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSensorIdentifier();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAlreadyRegistered();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFirstReadingTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAvailableDirectionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.MetiundoMeteringPoint.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.MetiundoMeteringPoint.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string melo = 3;
 * @return {string}
 */
proto.types.MetiundoMeteringPoint.prototype.getMelo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.setMelo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.types.MetiundoMeteringPoint.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sensor_identifier = 6;
 * @return {string}
 */
proto.types.MetiundoMeteringPoint.prototype.getSensorIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.setSensorIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool already_registered = 7;
 * @return {boolean}
 */
proto.types.MetiundoMeteringPoint.prototype.getAlreadyRegistered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.setAlreadyRegistered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int64 first_reading_time = 8;
 * @return {number}
 */
proto.types.MetiundoMeteringPoint.prototype.getFirstReadingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.setFirstReadingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated Direction available_directions = 9;
 * @return {!Array<!proto.types.Direction>}
 */
proto.types.MetiundoMeteringPoint.prototype.getAvailableDirectionsList = function() {
  return /** @type {!Array<!proto.types.Direction>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.types.Direction>} value
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.setAvailableDirectionsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.types.Direction} value
 * @param {number=} opt_index
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.addAvailableDirections = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.MetiundoMeteringPoint} returns this
 */
proto.types.MetiundoMeteringPoint.prototype.clearAvailableDirectionsList = function() {
  return this.setAvailableDirectionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.EnbwRegistrationDetails.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.types.EnbwRegistrationDetails.LocationIdCase = {
  LOCATION_ID_NOT_SET: 0,
  METERING_LOCATION_ID: 1,
  MARKET_LOCATION_ID: 2
};

/**
 * @return {proto.types.EnbwRegistrationDetails.LocationIdCase}
 */
proto.types.EnbwRegistrationDetails.prototype.getLocationIdCase = function() {
  return /** @type {proto.types.EnbwRegistrationDetails.LocationIdCase} */(jspb.Message.computeOneofCase(this, proto.types.EnbwRegistrationDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.EnbwRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.EnbwRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.EnbwRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnbwRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    meteringLocationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketLocationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    meterId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.EnbwRegistrationDetails}
 */
proto.types.EnbwRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.EnbwRegistrationDetails;
  return proto.types.EnbwRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.EnbwRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.EnbwRegistrationDetails}
 */
proto.types.EnbwRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeteringLocationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketLocationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.EnbwRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.EnbwRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.EnbwRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnbwRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMeterId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string metering_location_id = 1;
 * @return {string}
 */
proto.types.EnbwRegistrationDetails.prototype.getMeteringLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EnbwRegistrationDetails} returns this
 */
proto.types.EnbwRegistrationDetails.prototype.setMeteringLocationId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.types.EnbwRegistrationDetails.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.EnbwRegistrationDetails} returns this
 */
proto.types.EnbwRegistrationDetails.prototype.clearMeteringLocationId = function() {
  return jspb.Message.setOneofField(this, 1, proto.types.EnbwRegistrationDetails.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnbwRegistrationDetails.prototype.hasMeteringLocationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string market_location_id = 2;
 * @return {string}
 */
proto.types.EnbwRegistrationDetails.prototype.getMarketLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EnbwRegistrationDetails} returns this
 */
proto.types.EnbwRegistrationDetails.prototype.setMarketLocationId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.types.EnbwRegistrationDetails.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.EnbwRegistrationDetails} returns this
 */
proto.types.EnbwRegistrationDetails.prototype.clearMarketLocationId = function() {
  return jspb.Message.setOneofField(this, 2, proto.types.EnbwRegistrationDetails.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnbwRegistrationDetails.prototype.hasMarketLocationId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string meter_id = 3;
 * @return {string}
 */
proto.types.EnbwRegistrationDetails.prototype.getMeterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EnbwRegistrationDetails} returns this
 */
proto.types.EnbwRegistrationDetails.prototype.setMeterId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 start_time = 4;
 * @return {number}
 */
proto.types.EnbwRegistrationDetails.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.EnbwRegistrationDetails} returns this
 */
proto.types.EnbwRegistrationDetails.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.BitShakeRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.BitShakeRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.BitShakeRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.BitShakeRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.BitShakeRegistrationDetails}
 */
proto.types.BitShakeRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.BitShakeRegistrationDetails;
  return proto.types.BitShakeRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.BitShakeRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.BitShakeRegistrationDetails}
 */
proto.types.BitShakeRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.BitShakeRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.BitShakeRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.BitShakeRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.BitShakeRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ip = 1;
 * @return {string}
 */
proto.types.BitShakeRegistrationDetails.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.BitShakeRegistrationDetails} returns this
 */
proto.types.BitShakeRegistrationDetails.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.IoMeterRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.IoMeterRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.IoMeterRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.IoMeterRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.IoMeterRegistrationDetails}
 */
proto.types.IoMeterRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.IoMeterRegistrationDetails;
  return proto.types.IoMeterRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.IoMeterRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.IoMeterRegistrationDetails}
 */
proto.types.IoMeterRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.IoMeterRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.IoMeterRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.IoMeterRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.IoMeterRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.IoMeterRegistrationDetails.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.IoMeterRegistrationDetails} returns this
 */
proto.types.IoMeterRegistrationDetails.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MeteocontrolRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MeteocontrolRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MeteocontrolRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MeteocontrolRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inverterId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MeteocontrolRegistrationDetails}
 */
proto.types.MeteocontrolRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MeteocontrolRegistrationDetails;
  return proto.types.MeteocontrolRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MeteocontrolRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MeteocontrolRegistrationDetails}
 */
proto.types.MeteocontrolRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInverterId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MeteocontrolRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MeteocontrolRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MeteocontrolRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MeteocontrolRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInverterId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.types.MeteocontrolRegistrationDetails.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MeteocontrolRegistrationDetails} returns this
 */
proto.types.MeteocontrolRegistrationDetails.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inverter_id = 2;
 * @return {string}
 */
proto.types.MeteocontrolRegistrationDetails.prototype.getInverterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MeteocontrolRegistrationDetails} returns this
 */
proto.types.MeteocontrolRegistrationDetails.prototype.setInverterId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string access_token = 3;
 * @return {string}
 */
proto.types.MeteocontrolRegistrationDetails.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MeteocontrolRegistrationDetails} returns this
 */
proto.types.MeteocontrolRegistrationDetails.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ChargecloudRegistrationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ChargecloudRegistrationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ChargecloudRegistrationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ChargecloudRegistrationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    evseId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ChargecloudRegistrationDetails}
 */
proto.types.ChargecloudRegistrationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ChargecloudRegistrationDetails;
  return proto.types.ChargecloudRegistrationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ChargecloudRegistrationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ChargecloudRegistrationDetails}
 */
proto.types.ChargecloudRegistrationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ChargecloudRegistrationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ChargecloudRegistrationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ChargecloudRegistrationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ChargecloudRegistrationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvseId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string evse_id = 1;
 * @return {string}
 */
proto.types.ChargecloudRegistrationDetails.prototype.getEvseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ChargecloudRegistrationDetails} returns this
 */
proto.types.ChargecloudRegistrationDetails.prototype.setEvseId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.types);
